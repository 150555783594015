import { Button, Dialog, DialogPanel, DialogTitle, DialogBackdrop } from '@headlessui/react'
import { useState } from 'react'
import { ExclamationTriangleIcon, XMarkIcon } from '@heroicons/react/24/outline'
import SelectBox from './generic/SelectBox'
import { useLocation } from 'react-router-dom'

export default function EsignScheduledModal({ fields, recipients, closeModal }) {
  const [isOpen, setIsOpen] = useState(true)
  const [showScheduledDetails, setShowScheduledDetails] = useState(false)
  const [isEsignScheduled, setIsEsignScheduled] = useState(false)
  const [scheduledDate, setScheduledDate] = useState('')

  const location = useLocation()

  function close() {
    setIsOpen(false)
    closeModal()
  }

  function handleSaveModal() {
    const data = {
      isEsignScheduled: isEsignScheduled,
      isTemplateRequired: true,
      templateName: 'dummy'
    }
    if (isEsignScheduled) {
      data.scheduledDate = scheduledDate
    }
    closeModal(data)
  }

  function handleShowSchedulaData() {
    const type = location.search.split('&')[2]?.split('uploadType=')[1]
    if (type === 'template') {
      closeModal({
        isEsignScheduled: false,
        isTemplateRequired: true,
        templateName: 'dummy'
      })
    }
    setShowScheduledDetails(true)
  }

  const recipientFieldList = {}
  recipients.forEach((recipient) => {
    recipientFieldList[recipient.recipientId] = {
      email: recipient.email,
      fields: 0
    }
  })
  fields.forEach((field) => recipientFieldList[field.recipientId]['fields']++)

  return (
    <>
      <Dialog open={isOpen} as="div" className="relative z-50 focus:outline-none" onClose={close}>
        {/* The backdrop, rendered as a fixed sibling to the panel container */}
        <DialogBackdrop className="fixed inset-0 bg-black/50" />
        <div className="fixed inset-0 w-screen overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4">
            <DialogPanel
              transition
              className="w-full max-w-2xl px-10 rounded-xl bg-white p-6 backdrop-blur-2xl duration-300 ease-out data-[closed]:transform-[scale(95%)] data-[closed]:opacity-0"
            >
              <div className='flex justify-between items-end mb-10'>
                <DialogTitle as="h3" className="text-xl mt-5 font-medium flex items-center justify-center space-x-3">
                  <ExclamationTriangleIcon className={`${!Object.values(recipientFieldList).every((field) => field.fields > 0) && 'text-red-500'} text-blue-600 w-8 h-8`} />
                  {showScheduledDetails ? <span>Esign Settings</span> : Object.values(recipientFieldList).every((field) => field.fields > 0) ? <span>Confirm Details</span> : <span className='text-red-500'>Fields Required</span>}
                </DialogTitle>
                <XMarkIcon onClick={() => closeModal()} className='text-red-500 w-6 h-6 cursor-pointer hover:text-red-600' />
              </div>

              {showScheduledDetails ? <div>
                <div className='flex justify-center items-center space-x-5'>
                  <span className='w-full'>Want to Schedule Esign?</span>
                  <SelectBox list={[{ 'id': '1', 'name': 'False' }, { 'id': '2', 'name': 'True' }]} updateSelectedValue={(value) => setIsEsignScheduled(value.name === 'True')} />
                </div>

                {isEsignScheduled && <div className='flex justify-center my-5 items-center space-x-5'>
                  <span className='w-full'>Scheduled Date & Time</span>
                  <input type="datetime-local" name="" id="" className='border border-gray-200 py-1 px-2 rounded-lg w-full' onChange={(e) => setScheduledDate(e.target.value)} />
                </div>}
                <div className="mt-8 w-full flex items-end justify-end">
                  <Button
                    className="inline-flex items-center gap-2 rounded-md bg-blue-700 py-1.5 px-3 text-sm/6 font-semibold text-white shadow-inner shadow-white/10 focus:outline-none data-[hover]:bg-blue-600 data-[focus]:outline-1 data-[focus]:outline-white data-[open]:bg-gray-700"
                    onClick={() => handleSaveModal()}
                  >
                    Save Coordinates
                  </Button>
                </div>
              </div> : 
              Object.values(recipientFieldList).every((field) => field.fields > 0) ? <div>
                <span>Please verify the number of fields added for each recipient and confirm</span>
                <div className='mt-5'>
                  <div className='bg-gray-200 py-2 px-4 flex justify-between items-center'>
                    <span>Recipients</span>
                    <span>No. of fields</span>
                  </div>
                  {
                    Object.values(recipientFieldList).map((field, index) => {
                      return (
                        <div key={index} className='py-2 px-4 flex justify-between items-center'>
                          <span>{field.email}</span>
                          <span>{field.fields}</span>
                        </div>
                      )
                    })
                  }
                  <div className="mt-8 w-full flex items-end justify-end">
                    <Button
                      className="inline-flex items-center gap-2 rounded-md bg-blue-700 py-1.5 px-3 text-sm/6 font-semibold text-white shadow-inner shadow-white/10 focus:outline-none data-[hover]:bg-blue-600 data-[focus]:outline-1 data-[focus]:outline-white data-[open]:bg-gray-700"
                      onClick={() => handleShowSchedulaData()}
                    >
                      Confirm
                    </Button>
                  </div>
                </div>
              </div> : <div>
                <span>Add atleast one field for {Object.values(recipientFieldList).filter(field => field.fields === 0).length > 1 && ' the following recipients:'}</span>
                {
                  Object.values(recipientFieldList).filter(field => field.fields === 0).map((field, index) => {
                    return (
                      <div key={field.email} className='text-gray-700'>
                        {Object.values(recipientFieldList).filter(field => field.fields === 0).length > 1 && <span>{`${index +1}. `}</span>}
                        <span>{field.email}</span>
                      </div>
                    )
                  })
                }
                <span className='mt-16 flex w-full underline justify-end cursor-pointer' onClick={close}>Close</span>
              </div>}

            </DialogPanel>
          </div>
        </div>
      </Dialog>
    </>
  )
}
