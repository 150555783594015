import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { esignAPI, thirdView } from "../APIRoutes";
import { checkValidityOfToken, showMessage } from "../utils/helper";
import { regenerateToken } from "./UserStore";

const initialState = {
    currentDocumentId: '',
    currentDocument: null,
    recipientList: [],
    currentSelectedRecipient: {},
    selectedSignatureImage: {},
    viewType: "",
    navigatingUrl: ""
}

export const documentSlice = createSlice({
    name: 'files',
    initialState,
    reducers: {
        setCurrentDocumentId: (state, action) => {
            state.currentDocumentId = action.payload
        },
        setCurrentDocument: (state, action) => {
            state.currentDocument = action.payload
        },
        updateRecipientList: (state, action) => {
            state.recipientList = action.payload
        },
        updateCurrentSelectedRecipient: (state, action) => {
            state.currentSelectedRecipient = action.payload
        },
        updateSelectedSignatureImage: (state, action) => {
            state.selectedSignatureImage = action.payload
        },
        setViewType: (state, action) => {
            state.viewType = action.payload
        },
        setNavigatingUrl: (state, action) => {
            state.navigatingUrl = action.payload
        }
    }
})

export const fetchDocument = async (docId, userAuthToken) => {
    try {
        const response = await fetch(`${esignAPI}/getDocument?documentId=${docId}`, {
            method: 'GET',
            headers: {
            'Content-Type': 'application/pdf',
            AuthToken: userAuthToken,
            },
        });

        
        // Convert response to ArrayBuffer
        const data = await response.arrayBuffer();
        
        // Create a Blob from the ArrayBuffer
        const blob = new Blob([data], { type: 'application/pdf' });
        
        const binary = new Uint8Array(data);
      
      // Create an object URL from the Blob
      const blobUrl = URL.createObjectURL(blob);
      return [blobUrl, data]
    } catch (e) {
        showMessage(e, 'error')
    }
}

export const { setCurrentDocumentId, setCurrentDocument, updateRecipientList, updateCurrentSelectedRecipient, updateSelectedSignatureImage, setNavigatingUrl, setViewType } = documentSlice.actions
export default documentSlice.reducer
