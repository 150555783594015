import React, { useEffect, useState, useRef, useCallback } from 'react'
import { pdfjs, Document, Page } from 'react-pdf'
import "react-pdf/dist/Page/AnnotationLayer.css";
import "react-pdf/dist/Page/TextLayer.css";
import { BuildingOffice2Icon, CalendarDaysIcon, EnvelopeIcon, PencilIcon, ShareIcon, ArrowDownTrayIcon, PrinterIcon, ArrowsPointingOutIcon, XMarkIcon } from "@heroicons/react/24/outline"
import { Fields } from '../components/docViewer/Fields';
import { LuRotateCcw, LuRotateCw } from "react-icons/lu";
import { FaSignature } from "react-icons/fa6";
import { useDispatch, useSelector } from 'react-redux';
import SignatureModal from '../components/docViewer/SignatureModal';
import { useLocation, useNavigate } from 'react-router-dom';
import { esignAPI, thirdView } from '../APIRoutes';
import { TbTextRecognition } from 'react-icons/tb';
import { BsPersonStanding } from 'react-icons/bs';
import { fetchDocument, setCurrentDocumentId } from '../stores/DocumentSlice';
import EsignScheduledModal from '../components/EsignScheduledModal';
import { checkValidityOfToken, showMessage } from '../utils/helper';
import 'shepherd.js/dist/css/shepherd.css';
import Shepherd from 'shepherd.js';
import html2canvas from 'html2canvas';
import { getAPIRequest, postAPIRequest } from '../utils/fetchAPIs';

export const DocViewer = () => {

    const fields = [
        { fieldId: '1', name: 'Signature', icon: PencilIcon },
        { fieldId: '2', name: 'Initial', icon: FaSignature },
        { fieldId: '3', name: 'Company', icon: BuildingOffice2Icon },
        { fieldId: '4', name: 'Email', icon: EnvelopeIcon },
        { fieldId: '5', name: 'Sign Date', icon: CalendarDaysIcon },
        { fieldId: '6', name: 'Current Date', icon: CalendarDaysIcon },
        { fieldId: '7', name: 'Job Title', icon: TbTextRecognition },
        { fieldId: '8', name: 'Full Name', icon: BsPersonStanding },
    ]

  const colors = ["green", "red", "orange", "yellow", "lime", "emerald", "teal", "blue", "indigo", "fuchsia", "pink", "slate"]


    const [viewMode, setViewMode] = useState('edit')
    const [totalPages, settotalPages] = useState(0);
    const [pageNumber, setpageNumber] = useState(1);
    const [selectedFields, setSelectedFields] = useState([])
    const [openSignatureModal, setOpenSignatureModal] = useState(false)
    const [openEsignScheduledModal, setOpenEsignScheduledModal] = useState(false)
    const [renderedPages, setRenderedPages] = useState({})
    const [url, setUrl] = useState('')
    const [uploadType, setUploadType] = useState('')
    const [templateId, setTemplateId] = useState('')
    const [selectedFieldId, setSelectedFieldId] = useState(null)
    const [draggingFieldIndex, setDraggingFieldIndex] = useState(null)
    const [pageDimensions, setPageDimensions] = useState([]);
    const dispatch = useDispatch()

    const documentId = useSelector(state => state.document.currentDocumentId)
    const userAuthToken = useSelector(state => state.user.userToken)
    const selectedRecipient = useSelector(state => state.document.currentSelectedRecipient)
    const allRecipients = useSelector(state => state.document.recipientList)

    const pageRefs = useRef([]);
    const observer = useRef(null);
    const navigate = useNavigate()
    const location = useLocation()
    const fieldRefs = useRef([])
    const dragData = useRef({
        offsetX: 0,
        offsetY: 0
    });

    function retrieveSizeOfComponents(idx, pageIndex) {
        const { actualWidth, actualHeight } = pageDimensions[pageIndex];

        const scaleX = actualWidth / 1100;
        const scaleY = actualHeight / 1500;

        const rect = fieldRefs.current[idx].getBoundingClientRect()
        return [rect.height * scaleY, rect.width * scaleX]
    }

    function normaliseCoordinates(x, y, pageIndex) {
        const { actualWidth, actualHeight } = pageDimensions[pageIndex];
        const scaleX = actualWidth / 1100;
        const scaleY = actualHeight / 1500;

        const normalizedX = x * scaleX;
        const normalizedY = y * scaleY;

        return [normalizedX, normalizedY];
    }

    async function uploadCoordinates(esignScheduledData) {
        try {
            const data = {
                "docId": documentId,
                "esignComponentAndCoordinate": selectedFields.map((field, index) => {
                    const [normalisedX, normalisedY] = normaliseCoordinates(field.x, field.y, field.page - 1)
                    const [height, width] = retrieveSizeOfComponents(index, field.page - 1)
                    return ({
                        "id": field.id,
                        "componentName": field.name,
                        "componentValue": "",
                        "x_coordinates": normalisedX,
                        "y_coordinates": normalisedY,
                        "pageNo": field.page,
                        recipientId: field.recipientId,
                        componentHeight: height,
                        componentWidth: width
                    })
                }),
                "template": {
                    "process": "ESIGN",
                    "createTemplate": esignScheduledData.isTemplateRequired,
                    "templateName": esignScheduledData.templateName || ""
                },
                "esignScheduled": {
                    "isEsignScheduled": false,
                }
            }

            if (uploadType === 'template') {
                data.template.process = "TEMPLATE_CREATION"
            }

            if (esignScheduledData.isEsignScheduled) {
                const newDate = new Date(esignScheduledData.scheduledDate)
                data.esignScheduled = {
                    'isEsignScheduled': esignScheduledData.isEsignScheduled,
                    'day': `${newDate.getDate()}/${newDate.getMonth() + 1}/${newDate.getFullYear()}`,
                    'time': `${newDate.getHours() > 12 ? newDate.getHours() - 12 : newDate.getHours()} ${newDate.getHours() > 11 ? 'PM' : 'AM'}`
                }
            }

            const headers = {
                AuthToken: userAuthToken
            }

            const updateCoordinatesResponse = await postAPIRequest(`${esignAPI}/updateEsignCoordinates`, data, headers)
            showMessage(updateCoordinatesResponse.response.Message)
            setOpenEsignScheduledModal(false)
            navigate('/documents')
        } catch (error) {
            showMessage(error.response.data.Response.response, 'error')
        }
    }

    function generateFourDigitNumber() {
        const min = 1000; // Smallest 4-digit number
        const max = 9999; // Largest 4-digit number
        return Math.floor(Math.random() * (max - min + 1)) + min;
    }

    function handleOnDrop(e, pageNumber) {
        e.preventDefault();

        setDraggingFieldIndex(null)

        const fieldType = JSON.parse(e.dataTransfer.getData("fieldType"));
        const positions = JSON.parse(e.dataTransfer.getData("positions"));
        const pageRef = pageRefs.current[pageNumber];  // Get the ref for the page
        // Check if pageRef exists and is valid
        if (!pageRef) {
            console.error("Page reference is not available for page number:", pageNumber);
            return;
        }

        let height = 48;
        let width = 208;

        if (draggingFieldIndex !== null) {
            const fieldRef = fieldRefs.current[draggingFieldIndex]
            const fieldRect = fieldRef.getBoundingClientRect();
            height = fieldRect.height
            width = fieldRect.width
        }

        const rect = pageRef.getBoundingClientRect(); // Get the bounding box of the page
        if (fieldType.id) {
            const updatedFields = selectedFields.map((field) => {
                if (field.id === fieldType.id) {
                    let droppedX = e.clientX - rect.left - positions.x - 0.5
                    let droppedY = e.clientY - rect.top - positions.y - 0.5

                    if (droppedX < 0) droppedX = 0
                    if (droppedY < 0) droppedY = 0
                    if (droppedX + width > 1100) droppedX = 1100 - width
                    if (droppedY + height > 1500) droppedY = 1500 - height

                    return {
                        ...field,
                        x: droppedX, // X relative to the page
                        y: droppedY,  // Y relative to the page
                        page: pageNumber // Ensure the field is set to the correct page
                    };
                }
                return field;
            });
            setSelectedFields([...updatedFields]);
            return;
        }

        const selectedFieldType = fields.find((field) => field.fieldId === fieldType.fieldId);
        const selectedField = {
            ...selectedFieldType,
            id: generateFourDigitNumber(),
            x: e.clientX - rect.left - positions.x - 0.5,  // X relative to the page
            y: e.clientY - rect.top - positions.y - 0.5,   // Y relative to the page
            page: pageNumber,         // Store the page number the field is on
            recipientId: selectedRecipient.recipientId
        };

        setSelectedFields([...selectedFields, selectedField]);
    }


    function handleDragOver(e, index) {
        e.preventDefault();

        const pageRef = pageRefs.current[index];  // Get the ref for the page

        // Check if pageRef exists and is valid
        if (!pageRef) {
            console.error("Page reference is not available for page number:", index);
            return;
        }

        const rect = pageRef.getBoundingClientRect(); // Get the bounding box of the page

        let newX = e.clientX - rect.left - dragData.current.offsetX - 0.5;
        let newY = e.clientY - rect.top - dragData.current.offsetY - 0.5;
        let height = 48;
        let width = 208;

        if (draggingFieldIndex !== null) {
            const fieldRef = fieldRefs.current[draggingFieldIndex]
            const fieldRect = fieldRef.getBoundingClientRect();
            height = fieldRect.height
            width = fieldRect.width
        }

        // Restrict within boundaries
        newX = Math.max(0, Math.min(newX, rect.width - width));
        newY = Math.max(0, Math.min(newY, rect.height - height));

        const selectedField = selectedFields[draggingFieldIndex];
        const updateFields = selectedFields.map((field, index) => {
            if (index === draggingFieldIndex) {
                return {
                    ...field,
                    x: newX,
                    y: newY
                }
            }
            return field;
        })
        setSelectedFields([...updateFields])

    }

    function handleOnDragStart(e, field, index) {

        // Create an invisible image to use as the drag image
        const invisibleElement = document.createElement('div');
        invisibleElement.style.width = '0px';
        invisibleElement.style.height = '0px';
        document.body.appendChild(invisibleElement);

        e.dataTransfer.setDragImage(invisibleElement, 0, 0);

        // Cleanup after the drag ends
        setTimeout(() => {
            document.body.removeChild(invisibleElement);
        }, 0);

        const rect = fieldRefs.current[index].getBoundingClientRect();
        // Calculate the offset between the cursor and the field's top-left corner
        dragData.current.offsetX = e.clientX - rect.left;
        dragData.current.offsetY = e.clientY - rect.top;

        setDraggingFieldIndex(index)

        e.dataTransfer.setData("fieldType", JSON.stringify(field));
        e.dataTransfer.setData("positions", JSON.stringify({ x: dragData.current.offsetX, y: dragData.current.offsetY }))
    }

    function removeField(field) {
        const fields = selectedFields.filter((fieldType) => fieldType.id !== field.id)
        setSelectedFields([...fields])
    }

    function showSpecificModals(fieldType, field) {
        if (fieldType === 'Signature' || fieldType === 'Initial') {
            setOpenSignatureModal(true)
            setSelectedFieldId(field)
        }
    }

    function closeSignatureModal(imgUrl) {
        if (imgUrl) {
            const newFields = selectedFields.map((field) => {
                if (field.name === selectedFieldId.name) {
                    field.componentValue = imgUrl
                }
                return field
            })
            setSelectedFields([...newFields])
        }
        setOpenSignatureModal(false)
        setSelectedFieldId(null)
    }

    function closeEsignScheduledModal(data) {
        setOpenEsignScheduledModal(false);
        if (data) uploadCoordinates(data)
    }

    const scrollToPage = (pageIndex) => {
        const page = pageRefs.current[pageIndex];
        if (page) {
            page.scrollIntoView({ behavior: 'smooth' });
            setpageNumber(pageIndex)
        }
    };

    // Function to observe when a page is in view and update the current page number
    const observePages = useCallback(() => {
        if (observer.current) {
            observer.current.disconnect();  // Disconnect the old observer
        }


        // Create a new IntersectionObserver
        observer.current = new IntersectionObserver(
            (entries) => {
                const visiblePage = entries.find((entry) => entry.isIntersecting);
                if (visiblePage) {
                    const pageIndex = pageRefs.current.indexOf(visiblePage.target);
                    setpageNumber(pageIndex);  // Update current page number
                }
            },
            {
                threshold: 0.25,  // Trigger when 60% of the page is visible
            }
        );

        // Observe all pages
        pageRefs.current.forEach((page) => {
            if (page) observer.current.observe(page);
        });
    }, []);

    /**
     *
     * @param {Object} event
     *
     * This function will be called when pdf is loaded
     */
    function onDocLoad(event) {
        settotalPages(event.numPages);
    }

    const onRenderSuccess = (pageNumber) => {
        // Mark the page as rendered
        setRenderedPages((prevState) => ({
            ...prevState,
            [pageNumber]: true,
        }));
    };

    function setupCustomTour() {
        const tour = new Shepherd.Tour({
            useModalOverlay: true,
            defaultStepOptions: {
                scrollTo: { behavior: 'smooth', block: 'center' },
                cancelIcon: {
                    enabled: true
                },
                classes: 'custom-tour-class',
                buttons: [
                    {
                        text: 'Next',
                        action: Shepherd.next
                    }
                ]
            }
        });

        // Function to scroll to a specific page
        const scrollToPages = (pageNumber) => {
            return new Promise((resolve) => {
                if (renderedPages[pageNumber]) {
                    // Page is rendered, scroll to it
                    pageRefs.current[pageNumber].scrollIntoView({
                        behavior: 'smooth',
                        block: 'start'
                    });
                    resolve(); // Resolve the promise after scrolling
                } else {
                    // Wait for the page to be rendered before scrolling
                    const interval = setInterval(() => {
                        if (renderedPages[pageNumber]) {
                            clearInterval(interval);
                            pageRefs.current[pageNumber].scrollIntoView({
                                behavior: 'smooth',
                                block: 'start'
                            });
                            resolve();
                        }
                    }, 100); // Check every 100ms if the page is rendered
                }
            });
        };


        const remainingSelectedFieldsIndexes = []
        selectedFields.forEach((field, index) => {if (field.componentValue === field.name) remainingSelectedFieldsIndexes.push(index + 1)})

        // Define Steps
        selectedFields.forEach((field, index) => {
            if (field.componentValue === field.name) {
                if (index === (remainingSelectedFieldsIndexes.at(-1) - 1)) {
                    tour.addStep({
                        id: `step-${index + 1}`,
                        text: `${index + 1} tour`,
                        attachTo: {
                            element: `#step-${index + 1}`,
                            on: 'bottom'
                        },
                        buttons: [
                            {
                                text: 'Next',
                                action: () => {tour.show(`step-${remainingSelectedFieldsIndexes[0]}`)}
                            },
                            {
                                text: 'Complete',
                                action: tour.complete
                            }
                        ],
                        beforeShowPromise: () => scrollToPages(field.page),
                    })
                    return;
                }
                tour.addStep({
                    id: `step-${index + 1}`,
                    text: `${index + 1} tour`,
                    attachTo: {
                        element: `#step-${index + 1}`,
                        on: 'bottom'
                    },
                    buttons: [
                        {
                            text: 'Exit',
                            action: tour.cancel
                        },
                        {
                            text: 'Next',
                            action: tour.next
                        }
                    ],
                    beforeShowPromise: () => scrollToPages(field.page),
                })
            }
        })

        // Start the tour when component loads
        tour.start();
    }

    useEffect(() => {
        // On load adding pdfjs worker
        pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

        observePages();
    }, [observePages, totalPages]);

    useEffect(() => {
        observePages()
        if (pageDimensions.length === totalPages) {
            if (viewMode === 'esignView') {
                const validity = checkValidityOfToken(userAuthToken)
                if (validity === 'VALID' || validity === 'REGENERATE') getCoordinatesForThirdView()
                return;
            } 
            else if (templateId) {
                fetchTemplateDetails()              
            }
        }
    }, [pageDimensions, userAuthToken])

    // Function to convert normalized coordinates back to actual ones
    function denormalizeCoordinates(normalizedX, normalizedY, height, width, pageIndex) {
        const { actualWidth, actualHeight } = pageDimensions[pageIndex];

        // Calculate the scale factors
        const scaleX = actualWidth / 1100;
        const scaleY = actualHeight / 1500;

        // Calculate normalized height and width of the component back to actual
        const actualComponentWidth = width / scaleX;
        const actualComponentHeight = height / scaleY;

        // Convert normalized coordinates back to actual (predefined) coordinates
        const actualX = normalizedX / scaleX;
        const actualY = normalizedY / scaleY;
        return { actualX, actualY, actualComponentWidth, actualComponentHeight };
    }

    async function fetchTemplateDetails() {
        if(!templateId) return
        try {
            const headers = {
                'AuthToken': userAuthToken,
                'Content-Type': 'application/json'
            }
            const fetchDocumentDetailsResponse = await getAPIRequest(`${esignAPI}/getEsignDetailsByTemplateId?templateId=${templateId}`, headers)
            const allCoordinates = fetchDocumentDetailsResponse.response.esignComponentAndCoordinateEntities
            const coordinates = allCoordinates.map((coordinate, index) => {
                const { actualX, actualY, actualComponentWidth, actualComponentHeight } = denormalizeCoordinates(coordinate.x_coordinates, coordinate.y_coordinates, coordinate.componentHeight, coordinate.componentWidth, parseInt(coordinate.pageNo) - 1)
                const selectedField = fields.find((field) => field.name === coordinate.componentName)
                return {
                    ...selectedField,
                    id: coordinate.id,
                    name: coordinate.componentName,
                    componentValue: coordinate.componentValue || coordinate.componentName,
                    x: actualX,
                    y: actualY,
                    page: parseInt(coordinate.pageNo),
                    width: actualComponentWidth,
                    height: actualComponentHeight,
                    recipientId: coordinate.recipientId,
                    color: colors[index]
                }
            })
            setSelectedFields([...coordinates])
        } catch (error) {
            showMessage(error.response.data.Response.response, 'error')
        }
    }

    async function getCoordinatesForThirdView() {
        try {
            const headers = {
                AuthToken: userAuthToken
            }
            const coordinatesResponse = await getAPIRequest(`${thirdView}/getCoordinates`, headers, true, false)
            const allCoordinates = coordinatesResponse.response
            const coordinates = allCoordinates.map((coordinate) => {
                const { actualX, actualY, actualComponentWidth, actualComponentHeight } = denormalizeCoordinates(coordinate.x_coordinates, coordinate.y_coordinates, coordinate.componentHeight, coordinate.componentWidth, parseInt(coordinate.pageNo) - 1)
                const selectedField = fields.find((field) => field.name === coordinate.componentName)
                return {
                    ...selectedField,
                    id: coordinate.id,
                    name: coordinate.componentName,
                    componentValue: coordinate.componentValue || coordinate.componentName,
                    x: actualX,
                    y: actualY,
                    page: parseInt(coordinate.pageNo),
                    width: actualComponentWidth,
                    height: actualComponentHeight,
                }
            })
            setSelectedFields([...coordinates])
        } catch (error) {
            showMessage(error.response.data.Response.response, 'error')
        }
    }


    const fetchDocumentforEsignThirdView = async (docId) => {
        try {
            const headers = {
                AuthToken: userAuthToken
            }
            const getDocumentResponse = await getAPIRequest(`${thirdView}/getDocument?docId=${docId}`, headers, true, false)

            if (getDocumentResponse.message === 'SUCCESS') {
                const documentUrl = getDocumentResponse.response.documentUrl
                setUrl(documentUrl)
            }
        } catch (error) {
            showMessage(error.response.data.Response.response, 'error')
        }
    }

    async function changeSelectedValue(event, field) {
        if (!['1', '2'].includes(field.fieldId)) {
            const changedFields = selectedFields.map((selectedField) => {
                if (selectedField.id === field.id) {
                    return {
                        ...selectedField,
                        componentValue: event.target.value
                    }
                }
                return selectedField
            })
            setSelectedFields([...changedFields])
        }
    }

    async function createEsignedData() {
        const fieldPromises = selectedFields.map(async (field, index) => {
            const isImageAvailable = field.componentValue?.img;
            return {
                componentId: field.id,
                componentName: field.name,
                componentValue: isImageAvailable ? '' : field.componentValue,
                isImageAdded: Boolean(isImageAvailable),
                base64EncodedImage: isImageAvailable ? await convertDivToImage(index) : '',
            };
        });

        const abc = []
        const fields = await Promise.all(fieldPromises);
        fields.forEach(value => abc.push(value));
        return abc
    }

    function validateFields() {
        if (selectedFields.some((field) => field.componentValue === field.name)) {
            setupCustomTour()
            return false
        }
        return true
    }

    const handleSaveDocument = async () => {
        if (viewMode === 'edit' || viewMode === 'esignTemplate') {
            setOpenEsignScheduledModal(true)
        } else {
            const allFieldsValidated = validateFields()
            if (!allFieldsValidated) return;

            const headers = {
                'Content-Type': 'application/json',
                AuthToken: userAuthToken
            }
            const postData = {
                fields: await createEsignedData(),
            }
            try {
                const signDocumentResponse = await postAPIRequest(`${thirdView}/signDocument`, postData, headers, true, false)
                showMessage(signDocumentResponse.response.Message)
                navigate('/esignSuccess')
            } catch (error) {
                showMessage(error.response.data.Response.response, 'error')
            }
        }
    }

    const onPageLoadSuccess = (page, index) => {
        const { width: actualWidth, height: actualHeight } = page.getViewport({ scale: 1 });

        setPageDimensions((prev) => {
            const updated = [...prev];
            updated[index] = { actualWidth, actualHeight };
            return updated;
        });

    };

    async function resizeBase64Image(base64Str, maxWidth, maxHeight) {
        return new Promise((resolve, reject) => {
          const img = new Image();
      
          img.onload = function () {
            const canvas = document.createElement("canvas");
            const ctx = canvas.getContext("2d");
      
            let width = img.width;
            let height = img.height;
      
            // Calculate the aspect ratio and new dimensions
            const aspectRatio = width / height;
      
            if (width > height) {
              // Landscape
              width = Math.min(maxWidth, width);
              height = width / aspectRatio;
            } else {
              // Portrait or Square
              height = Math.min(maxHeight, height);
              width = height * aspectRatio;
            }
      
            // Set canvas dimensions
            canvas.width = width;
            canvas.height = height;
      
            // Draw the resized image
            ctx.drawImage(img, 0, 0, width, height);
      
            // Get the resized image as base64
            const resizedBase64 = canvas.toDataURL("image/png", 0.9);
            resolve(resizedBase64);
          };
      
          img.onerror = (err) => reject(err);
      
          img.src = base64Str; // Set the source of the image
        });
      }

    async function convertDivToImage(fieldIndex) {
        if (fieldIndex >= 0) {
            const clonedDiv = fieldRefs.current[fieldIndex].cloneNode(true);
            const field = selectedFields[fieldIndex]
            const [height, width] = retrieveSizeOfComponents(fieldIndex, field.page - 1)

            clonedDiv.classList.remove('border-2');
            clonedDiv.classList.remove('border-dashed');
            clonedDiv.classList.remove('bg-green-500/30');
            document.body.appendChild(clonedDiv);
            clonedDiv.style.position = 'absolute';
            clonedDiv.style.left = '-9999px';
            clonedDiv.style.height = `${field.height}px`
            clonedDiv.style.width = `${field.width}px`
            clonedDiv.style.display = 'flex'
            clonedDiv.style.alignItems = 'center'
            clonedDiv.style.justifyContent = 'center'
            // Wait for the canvas to render
            const canvas = await html2canvas(clonedDiv, {
                useCORS: true, // Ensure cross-origin images are loaded
                allowTaint: false, // Do not allow tainted canvases
                logging: true, // Enable logging for debugging
                onclone: (clonedDocument) => {
                    // Ensure necessary styles are applied to the cloned element
                    clonedDocument.querySelectorAll('.border-2, .border-dashed', 'bg-green-500/30').forEach((el) => {
                        el.classList.remove('border-2', 'border-dashed', 'bg-green-500/30');
                    });
                },
            });

            // Remove cloned div from DOM
            document.body.removeChild(clonedDiv);

            // Convert canvas to base64 URL
            const imageURL = canvas.toDataURL('image/png', 1);
            const resizedImage = await resizeBase64Image(imageURL, width, height)
            return resizedImage
        }
    }

    useEffect(() => {
        async function checkForDocuments() {
            const docId = location.search.split('&')[0].split('?documentId=')[1]
            const viewType = location.search.split('&')[1].split('viewType=')[1]
            const type = location.search.split('&')[2]?.split('uploadType=')[1]
            const id = location.search.split('&')[3]?.split('templateId=')[1]
            setTemplateId(id || '')
            setUploadType(type)
            dispatch(setCurrentDocumentId(docId))
            setViewMode(viewType)
            if (userAuthToken) {
                if (viewType === 'edit' || viewType === 'view' || viewType === 'esignTemplate') {
                    if (!docId) {
                        navigate('/uploadDocument')
                    } else {
                        const fetchedUrl = await fetchDocument(docId, userAuthToken)
                        setUrl(fetchedUrl[0])
                    }
                }
                else fetchDocumentforEsignThirdView(docId)
            }
        }
        checkForDocuments()
    }, [location, userAuthToken])

    useEffect(() => {
        if (viewMode === 'esignView' && Object.entries(renderedPages).length === totalPages) {
            setupCustomTour()
        }
    }, [viewMode, totalPages, renderedPages])

    return (
        <div className='overflow-hidden'>
            {openSignatureModal && <SignatureModal height={selectedFieldId.height} width={selectedFieldId.width} closeModal={(imgUrl) => closeSignatureModal(imgUrl)} />}
            {openEsignScheduledModal && <EsignScheduledModal fields={selectedFields} recipients={allRecipients} closeModal={(data) => closeEsignScheduledModal(data)} />}
            <div className='bg-[#353535] py-4 px-10 flex items-center justify-between text-white'>
                <div className="flex items-center justify-center font-semibold text-lg">
                    Pdf File Name
                    <PencilIcon className='w-5 h-5 ml-3' />
                </div>
                <div className="flex justify-center items-center gap-1">
                    {`${pageNumber} / ${totalPages}`}
                </div>
                <div className='flex space-x-5'>
                    <button className="bg-slate-200 text-black px-6 cursor-pointer py-2 rounded-lg">
                        Save & Close
                    </button>
                    <button onClick={() => handleSaveDocument()} className="bg-green-600 text-white px-6 cursor-pointer py-2 rounded-lg">
                        Continue
                    </button>
                </div>
            </div>
            <div className='flex py-4 items-center px-10 justify-between'>
                {viewMode !== 'esignView' ? <div className='flex space-x-5'>
                    <LuRotateCcw className='w-6 h-6' />
                    <LuRotateCw className='w-6 h-6' />
                    <PencilIcon className='w-6 h-6' />
                    <ArrowDownTrayIcon className='w-6 h-6' />
                    <PrinterIcon className='w-6 h-6' />
                    <ArrowsPointingOutIcon className='w-6 h-6' />
                    <span className='text-2xl -mt-1 flex justify-start items-start'>T</span>
                    <ShareIcon className='w-5 h-5' />
                </div> :
                    <div>
                        {`Remaining fields: ${selectedFields.filter((field) => field.componentValue === field.name).length}`}
                    </div>
                }
            </div>
            <div className=" w-full h-screen flex justify-start items-start overflow-hidden">
                <div>
                    <Fields viewMode={viewMode} />
                </div>
                <div className="w-full h-full">
                    <div className="w-full bg-slate-100 h-full">
                        <div className="w-full bg-slate-100 p-4 h-full overflow-auto flex justify-center items-start">
                            <Document file={url}>
                                {Array.from({ length: totalPages }, (el, index) => (
                                    <Page key={`page_${index + 1}`} pageNumber={index + 1} pageIndex={index + 1} height={1500} width={1100} onDrop={(e) => handleOnDrop(e, index + 1)} onDragOver={(e) => handleDragOver(e, index + 1)} inputRef={el => pageRefs.current[index + 1] = el} className="relative my-4" onLoadSuccess={(page) => onPageLoadSuccess(page, index)} onRenderSuccess={() => onRenderSuccess(index + 1)}>
                                        {
                                            selectedFields.map((field, fieldIndex) => {
                                                return (
                                                    <div key={field.id} >
                                                        {index + 1 === field.page && <div
                                                            className="flex justify-between z-40"
                                                            onClick={() => { viewMode !== 'edit' && showSpecificModals(field.name, field) }}
                                                            style={{
                                                                position: 'absolute',
                                                                left: `${field.x}px`,
                                                                top: `${field.y}px`,
                                                            }}
                                                        >
                                                            <div id={`step-${fieldIndex + 1}`}>
                                                                {
                                                                    viewMode === 'edit' && <span className='absolute -right-2 -top-2 bg-red-500 rounded-full !cursor-pointer text-white p-0.5 z-20' onClick={() => removeField(field)} draggable="false">
                                                                        <XMarkIcon className='w-4 h-4' />
                                                                    </span>
                                                                }
                                                                {
                                                                    viewMode === 'edit' || viewMode === 'esignTemplate' ? <textarea style={{ resize: 'both' }} className={`flex relative justify-center items-center !cursor-move min-w-52 min-h-12 z-10 border-dashed border-2 border-${allRecipients.find(recipient => recipient.recipientId === field.recipientId)?.color || 'green'}-600 bg-${allRecipients.find(recipient => recipient.recipientId === field.recipientId)?.color || 'green'}-300/30 !group focus:outline-none`} draggable={viewMode === 'edit' ? true : false} onDragStart={(e) => handleOnDragStart(e, field, fieldIndex)} ref={el => fieldRefs.current[fieldIndex] = el} readOnly={viewMode === 'edit' ? true : false} value={field.name} />
                                                                        : ['Signature', 'Initials'].includes(field.name) && !['Signature', 'Initials'].includes(field.componentValue || field.name) ?
                                                                            <div style={{ resize: 'both', eight: `${field.height}px`, width: `${field.width}px` }} draggable={viewMode === 'edit' ? true : false} ref={el => fieldRefs.current[fieldIndex] = el} className='flex relative justify-center items-center !cursor-pointer z-10 border-dashed border-2 border-green-500 bg-green-500/30 !group focus:outline-none'>
                                                                                <img src={field.componentValue.img} style={{ height: `${field.height}px`, width: `${field.width}px` }} draggable={viewMode === 'edit' ? true : false} className='!cursor-pointer relative z-10' alt="Uploaded" />
                                                                            </div>
                                                                            :
                                                                            <textarea style={{ resize: 'none', height: field.height, width: field.width }} className='flex relative justify-center items-center !cursor-pointer z-10 border-dashed border-2 border-green-500 bg-green-500/30 !group focus:outline-none' value={field.componentValue} ref={el => fieldRefs.current[fieldIndex] = el} onChange={(e) => changeSelectedValue(e, field)} />}
                                                            </div>
                                                        </div>}
                                                    </div>
                                                )
                                            })
                                        }
                                    </Page>
                                ))}
                            </Document>
                        </div>
                    </div>
                </div>
                <div className="border-r-2 border-gray-400 w-96 py-2 h-full">
                    <div className="px-2 py-3 border-b-2 text-center font-semibold text-lg">
                        Documents
                    </div>
                    <div className="h-full pb-20">
                        <Document
                            className={
                                "flex flex-col justify-start items-center overflow-auto h-full"
                            }
                            file={url}
                            onLoadSuccess={onDocLoad}
                        >
                            {Array(totalPages)
                                .fill()
                                .map((_, index) => (
                                    <div
                                        key={index}
                                        onClick={() => scrollToPage(index + 1)} // Fix page number here
                                        className={`border-[4px] cursor-pointer relative rounded my-2 ${pageNumber === index + 1 ? "border-green-700" : ""}`}
                                    >
                                        <Page height={250} pageNumber={index + 1} />
                                    </div>
                                ))}
                        </Document>
                    </div>
                </div>
            </div>
        </div>
    )
}
