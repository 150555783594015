import { ArrowPathIcon, ChevronDoubleLeftIcon, ChevronDoubleRightIcon, ChevronLeftIcon, ChevronRightIcon, EllipsisHorizontalIcon, FunnelIcon, MoonIcon, PlusIcon, TrashIcon } from '@heroicons/react/24/outline'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setCurrentDocument } from '../stores/DocumentSlice'
import { showMessage } from '../utils/helper'
import SelectBox from '../components/generic/SelectBox'
import { useNavigate } from 'react-router-dom'
import { LuLoader2 } from 'react-icons/lu'
import DateModal from '../components/generic/DateModal'
import { postAPIRequest } from '../utils/fetchAPIs'
import { esignAPI } from '../APIRoutes'

export const Templates = () => {
    const userAuthToken = useSelector(state => state.user.userToken)
    const dispatch = useDispatch()
    const [allTemplates, setAllTemplates] = useState([])
    const [pageLoad, setPageLoad] = useState('10')
    const [pageNo, setPageNo] = useState(1)
    const [totalPages, setTotalPages] = useState(0)
    const [totalTemplates, setTotalTemplates] = useState(0)
    const [loadingTemplates, setLoadingTemplates] = useState(false)
    const [filterOpen, setFilterOpen] = useState(false)
    const [showDateModal, setShowDateModal] = useState(false)
    const [reset, setReset] = useState(false)
    const [filterList, setFilterList] = useState({
        templateName: '',
        templateId: '',
        createdDateFrom: '',
        createdDateTo: ''
    })
    const navigate = useNavigate()

    function handlePageLoad(data) {
        setPageLoad(data.name)
    }

    function resetFilter() {
        setReset(true)
        setFilterList({
            templateName: '',
            templateId: '',
            createdDateFrom: '',
            createdDateTo: ''
        });
        getTemplates({
            templateName: '',
            templateId: '',
            createdDateFrom: '',
            createdDateTo: '',
            pageSize: pageLoad,
            pageNo: 0
        }); 
        setPageNo(1)
    }

    function handleChangePageNo(e) {
        if (e.target.value > totalPages) return setPageNo(totalPages);
        setPageNo(e.target.value)
    }

    function handleSpecificDocument(selectedDocument) {
        dispatch(setCurrentDocument(selectedDocument))
        navigate(`/uploadDocument/${selectedDocument.templateId}?type=template`)
    }

    function changePageNo(type) {
        if (type === 'prev') {
            if (pageNo > 1) {
                setPageNo(pageNo - 1)
            }
        }
        if (type === 'next') {
            if (pageNo < totalPages) {
                setPageNo(pageNo + 1)
            }
        }
    }

    function convertDateIntoStandardFormat(date) {
        const currentDate = new Date(date)
        return `${String(currentDate.getDate()).padStart(2, '0')}-${String(currentDate.getMonth()).padStart(2, '0')}-${currentDate.getFullYear()}`;
    }

    function getDateDaysBefore(days) {
        // Create a new Date object for the current date
        const currentDate = new Date();
    
        // Subtract the specified number of days from the current date
        currentDate.setDate(currentDate.getDate() - days);
    
        return currentDate;
    }

    function updateDateFilter(value) {
        if (value.name === 'Custom') {
            setShowDateModal(true)
        }
        else {
            let createdDateFrom = ''
            if (value.id == 1) {
                createdDateFrom = getDateDaysBefore(30)
            } else {
                createdDateFrom = getDateDaysBefore(7)
            }
            const currentDate = getDateDaysBefore(0)
            setFilterList({
                ...filterList,
                createdDateFrom: convertDateIntoStandardFormat(createdDateFrom),
                createdDateTo: convertDateIntoStandardFormat(currentDate)
            })
        } 
    }
    
    function updateDateFilterFromModal(dates) {
        setFilterList({
            ...filterList,
            createdDateFrom: convertDateIntoStandardFormat(dates.startdate),
            createdDateTo: convertDateIntoStandardFormat(dates.enddate)
        })
    }

    /**
     * Function to get templates list from server
     */
    async function getTemplates(bodyData='', defaultPageNo=false) {
        try {        
            if (pageNo > 0) {
                setLoadingTemplates(true)
                let body = {
                    "createdDateFrom": filterList.createdDateFrom,
                    "createdDateTo": filterList.createdDateTo,
                    "templateId": filterList.templateId,
                    "templateName": filterList.templateName,
                    "pageNo": defaultPageNo ? '1' : pageNo - 1,
                    "pageSize": pageLoad
                }
                if (bodyData) {
                    body = bodyData 
                }
                const headers = {
                    AuthToken: userAuthToken,
                    'Content-Type': 'application/json'
                }

                const getAllTemplatesResponses = await postAPIRequest(`${esignAPI}/getTemplatesFilter`, body, headers)
                if (getAllTemplatesResponses) {
                    setAllTemplates(getAllTemplatesResponses.response.data)
                    setTotalTemplates(getAllTemplatesResponses.response.totalData)
                    setTotalPages(getAllTemplatesResponses.response.totalPages)
                }
            }
        } catch (error) {
            showMessage(error.response.data.Response.response, 'error')
        } finally{
            setLoadingTemplates(false)
        }
    }

    useEffect(() => {
        if (userAuthToken) getTemplates()
    }, [userAuthToken, pageNo])

    useEffect(() => {
        setPageNo(1)
        if (userAuthToken) getTemplates()
    }, [pageLoad, userAuthToken])

  return (
    <div className='flex flex-col justify-end items-end w-full my-24'>
        <div className='w-[79%]'>
            {showDateModal && <DateModal selectedDate={updateDateFilterFromModal} closeModal={() => setShowDateModal(false)} />}
            <div className='flex px-16 justify-between items-center'>
                <div className='flex space-x-5 justify-center items-center'>
                    <div>
                        {`View ${allTemplates.length ? ((pageNo - 1) * pageLoad) + 1 : 0} - ${pageNo * pageLoad > totalTemplates ? totalTemplates : pageNo * pageLoad} of ${totalTemplates}`}
                    </div>
                    <div className='flex justify-center items-center space-x-5'>
                        <span>Show</span>
                        <SelectBox list={[{id: 0, name: '10'}, {id: 1, name: '20'}, {id: 2, name: '30'}]} updateSelectedValue={handlePageLoad} />
                    </div>
                    <TrashIcon className='w-5 h-5' />
                    <ArrowPathIcon className={`${loadingTemplates && 'animate-spin'} w-5 h-5 cursor-pointer hover:scale-110 hover:ease-in-out`} onClick={() => {!loadingTemplates && getTemplates(); setPageNo(1)}} />
                </div>
                <div className='flex space-x-5 items-center'>
                    <ChevronDoubleLeftIcon className={`${pageNo === 1 && '!cursor-not-allowed opacity-50'} w-5 h-5 cursor-pointer`} onClick={() => setPageNo(1)} />
                    <ChevronLeftIcon className={`${pageNo === 1 && '!cursor-not-allowed opacity-50'} w-5 h-5 cursor-pointer`} onClick={() => changePageNo('prev')} />
                    <input type="number" name="pageNo" value={pageNo} onChange={(e) => handleChangePageNo(e)} className='w-12 h-8 flex text-center border rounded-md focus:outline-none' />
                    <ChevronRightIcon className={`${pageNo === totalPages && '!cursor-not-allowed opacity-50'} w-5 h-5 cursor-pointer`} onClick={() => changePageNo('next')} />
                    <ChevronDoubleRightIcon className={`${pageNo === totalPages && '!cursor-not-allowed opacity-50'} w-5 h-5 cursor-pointer`} onClick={() => setPageNo(totalPages)} />
                    <span>|</span>
                    <FunnelIcon className='w-5 h-5 cursor-pointer' onClick={() => {setFilterOpen(!filterOpen);}} />
                    <div onClick={() => navigate('/uploadDocument/new?type=template')} className='bg-blue-800 hover:bg-blue-900 w-fit flex items-center justify-center space-x-3 cursor-pointer py-3 px-4 rounded-lg text-white'>
                        <PlusIcon className='w-5 h-5' />
                        <span>Create Template</span>
                    </div>
                </div>
            </div>

            <div className='mx-16 mt-5 border rounded-lg'>
                <div className='border-b bg-gray-200'>
                    <div className='flex w-[100%] px-4 py-3 gap-x-4 font-semibold text-gray-800 text-base'>
                        <span className='w-[5%] flex items-center'>S.No.</span>
                        <span className='w-[20%] flex items-center'>Template Name</span>
                        <span className='w-[15%] flex items-center'>Recipients</span>
                        <span className='w-[20%] flex items-center'>Created By</span>
                        <span className='w-[10%] flex items-center'>Created On</span>
                        <span className='w-[20%] flex items-center'>Modified By</span>
                        <span className='w-[10%] flex items-center'>Modified On</span>
                    </div>
                    {filterOpen && <div className='flex w-[100%] relative px-4 py-3 gap-x-4 font-semibold text-gray-800 text-base'>
                        <span className='w-[5%] flex' />
                        <span className='w-[20%] flex'>
                            <input type="text" name="templateName" onChange={(e) => {setFilterList({ ...filterList, [e.target.name]: e.target.value })}} value={filterList.templateName} className='w-56 rounded-lg h-[38px] focus:outline-none px-1.5 font-normal border border-gray-300' />
                        </span>
                        <span className='w-[15%] flex'>
                            <input type="text" name="templateId" onChange={(e) => {setFilterList({ ...filterList, [e.target.name]: e.target.value })}} value={filterList.templateId} className='w-40 rounded-lg h-[38px] focus:outline-none px-1.5 font-normal border border-gray-300' />
                        </span>
                        <span className='w-[20%] flex'>
                            <input type="text" name="recipientEmailId" onChange={(e) => {setFilterList({ ...filterList, [e.target.name]: e.target.value })}} value={filterList.recipientEmailId} className='w-56 rounded-lg h-[38px] focus:outline-none px-1.5 font-normal border border-gray-300' />
                        </span>
                        <span className='w-[10%] flex' />
                        <span className='w-[20%] flex'>
                            <SelectBox resetValue={reset} list={['--select--', '7 days', '30 days', 'Custom'].map((status, index) => ({id: index, name: status}))} updateSelectedValue={(value) => {value.id === 0 ? setReset(false) : updateDateFilter(value)}} valueResetted={() => setReset(false)} />
                        </span>
                        <span className='w-[10%] flex justify-center'>

                            <SelectBox resetValue={reset} list={['--select--', 'In Progress', 'Expired', 'Completed', 'Draft'].map((status, index) => ({id: index, name: status}))} updateSelectedValue={(value) => {value.id === 0 ? setReset(false) : setFilterList({...filterList, status: value.name.toLocaleUpperCase()})}} valueResetted={() => setReset(false)} />
                        </span>
                        <button className='w-[10%] flex border-2 bg-green-600 rounded-lg hover:bg-green-700/80 justify-center items-center text-white ' onClick={() => getTemplates()}>Filter</button>
                        <span className='absolute text-indigo-500 cursor-pointer right-7 underline hover:text-indigo-700 text-sm -top-2' onClick={() => {resetFilter();}}>Clear Filter</span>
                    </div>}
                </div>
                <div className='divide-y divide-gray-300'>
                    {
                        loadingTemplates ? <div className='flex justify-center space-x-3 items-center text-3xl font-semibold text-gray-400 h-96'>
                            <LuLoader2 className='w-8 h-8 animate-spin' />
                            <span>
                                Fetching Documents
                            </span>
                        </div> :
                        allTemplates.length ? allTemplates.map((template, index) => {
                            return (
                                <div key={template.docId} className='flex w-[100%] text-gray-800 font-medium px-4 gap-x-4 text-sm min-h-12 py-3.5 hover:bg-gray-100'>
                                    <span className='w-[5%] flex'>{((pageNo - 1) * pageLoad) + index + 1}</span>
                                    <span className='w-[20%] flex hover:underline cursor-pointer truncate' onClick={() => handleSpecificDocument(template)}>{template.templateName}</span>
                                    <span className='w-[15%] flex'>{template.recipientCount}</span>
                                    <span className='w-[20%] flex'>{template.createdBy}</span>
                                    <span className={`w-[10%]`}><span className={`flex justify-start items-start`}>{new Date(template.createdDate).toLocaleDateString()}</span></span>
                                    <div className='w-[20%] flex flex-col justify-start items-start'>{template.modifiedBy}</div>
                                    <span className='w-[10%] flex'>{new Date(template.modifiedDate).toLocaleDateString()}</span>
                                </div>
                            )
                        }) : <div className='flex justify-center items-center text-3xl font-semibold text-gray-400 h-96'>No templates found</div>
                    }
                </div>
            </div>
        </div>
    </div>
  )
}
