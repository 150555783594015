import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { updateCurrentSelectedRecipient, updateRecipientList } from '../../stores/DocumentSlice'
import { decodeJWT, showMessage } from '../../utils/helper'
import classNames from 'classnames'
import { getAPIRequest } from '../../utils/fetchAPIs'
import { esignAPI } from '../../APIRoutes'
import { useLocation } from 'react-router-dom'

export const Recipients = ({ viewMode }) => {

  const [recipientList, setRecipientList] = useState([])
  const [template, setTemplate] = useState('')
  const dispatch = useDispatch()
  const documentId = useSelector(state => state.document.currentDocumentId)
  const userAuthToken = useSelector(state => state.user.userToken)
  const location = useLocation()

  const colors = ["green", "red", "orange", "yellow", "lime", "emerald", "teal", "blue", "indigo", "fuchsia", "pink", "slate"]

  const fetchRecipientsList = async () => {
    try {
      const recipientsResponse = await getAPIRequest(`${esignAPI}/getRecipients?docId=${documentId}`, { AuthToken: userAuthToken })
      if (recipientsResponse.message === 'SUCCESS') {
        const list = recipientsResponse.response.map((recipient, index) => {
          return {
            ...recipient,
            color: colors[index],
            current: index === 0
          }
        })
        setRecipientList([...list])
        dispatch(updateRecipientList(list))
        dispatch(updateCurrentSelectedRecipient(list[0]))
      }

    } catch (error) {
      showMessage(error.response.data.Response.response, 'error')
    }
  }

  const handleRecipientChange = (recipientId) => {
    let newRecipientList = recipientList.map((recipient) => {
      if (recipient.current === true) {
        return { ...recipient, current: false }
      } else return recipient
    })
    newRecipientList = newRecipientList.map((recipient) => {
      if (recipient.recipientId === recipientId) {
        return { ...recipient, current: true }
      }
      return recipient
    })
    const selectedRecipient = newRecipientList.find((recipient) => recipient.current)
    dispatch(updateCurrentSelectedRecipient(selectedRecipient))
    setRecipientList([...newRecipientList])
  }

  async function fetchRecipientsByTemplate(id) {
    try {
      const templateResponse = await getAPIRequest(`${esignAPI}/getEsignDetailsByTemplateId?templateId=${id}`, { AuthToken: userAuthToken })
      const recipientCount = templateResponse.response.recipientCount
      const recipients = []
      for (let i = 0; i < recipientCount; i++) {
        recipients.push({ email: `Recipient ${i+1}`, name: '', recipientId: i + 1, color: colors[i], current: i === 0 })
      }
      setRecipientList(recipients)
      dispatch(updateRecipientList(recipients))
      dispatch(updateCurrentSelectedRecipient(recipients[0]))
    } catch (error) {
      showMessage(error.response.data.Response.response, 'error')
    }
  }

  useEffect(() => {
    const viewType = location.search.split('&')[1].split('viewType=')[1]
    const type = location.search.split('&')[2]?.split('uploadType=')[1]
    const templateId = location.search.split('&')[3]?.split('templateId=')[1]
    setTemplate(templateId)
    if (userAuthToken) {
      if (viewType === 'edit' || viewType === 'esignTemplate') {
        if (type === 'template') {
          fetchRecipientsByTemplate(templateId)
        } else {
          fetchRecipientsList()
        }
      } else if (viewType === 'esignView') {
        const decodedToken = decodeJWT(userAuthToken)
        setRecipientList([{ recipientId: 0, name: decodedToken.recipientName, email: decodedToken.recipientEmail, current: true, color: 'green' }])
      }
    }
  }, [userAuthToken, documentId, location])

  return (
    <div className='py-4 flex flex-col items-start border-b-2 border-gray-300'>
      <h3 className='text-2xl text-gray-600 font-medium px-8'>
        Recipients
      </h3>
      <p className='text-start pt-4 px-8'>
        Select a recipient for whom you need to add or request data:
      </p>
      <div className='flex flex-col items-start pt-4 px-8 space-y-5 max-h-52 overflow-y-auto w-full'>
        {
          recipientList.map((recipient) => {
            return (
              <div key={recipient.recipientId} className={classNames(`bg-${recipient.color}-300 px-3 items-center py-2 border-2 border-dashed ${!recipient.current ? `opacity-70` : recipient.color ? `border-${recipient.color}-600` : 'border-red-600'} w-full cursor-pointer`)} onClick={() => handleRecipientChange(recipient.recipientId)}>
                <span className='flex'>{recipient.name}</span>
                <span className='flex'>{recipient.email}</span>
              </div>
            )
          })
        }
      </div>
    </div>
  )
}
