import './App.css';
import { Sidebar } from './components/homepage/Sidebar'
import { DocViewer } from './pages/DocViewer';
import { HomePage } from './pages/HomePage';
import { BrowserRouter as Router, Routes as Switch, Route } from 'react-router-dom';
import { Documents } from './pages/Documents';
import PDFEditor from './pages/PDFEditor';
import Login from './pages/Login';
import { Test } from './pages/Test';
import CreateUser from './pages/Admin/CreateUser';
import TopNavbar from './components/homepage/TopNavbar';
import { useSelector } from 'react-redux';
import CreateAdmin from './pages/SuperAdmin/CreateAdmin';
import { UploadDocument } from './pages/UploadDocument';
import { ManageAdmin } from './pages/SuperAdmin/ManageAdmin';
import { ManageUser } from './pages/Admin/ManageUser';
import { ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { ValidateEsign } from './pages/ValidateEsign';
import { DocumentDetails } from './pages/DocumentDetails';
import { regenerateToken } from './utils/fetchAPIs';
import { checkValidityOfToken, decodeJWT } from './utils/helper';
import { useEffect } from 'react';
import { navigateToPage } from './utils/navigation';
import { LoadingComponent } from './components/generic/LoadingComponent';
import { Templates } from './pages/Templates';
import { EsignSuccessfull } from './pages/EsignSuccessfull';

function App() {
  const role = useSelector(state => state.user.userRole)
  const userAuthToken = useSelector(state => state.user.userToken)
  const showGenericLoading = useSelector(state => state.user.loading)
  const viewType = useSelector(state => state.document.viewType)
  const navigatingUrl = useSelector(state => state.document.navigatingUrl)

  useEffect(() => {
    if (userAuthToken) {
      const token = decodeJWT(userAuthToken)

      // Get the current time
      const now = new Date();

      // Calculate the difference in milliseconds
      const timeLeft = ((token.exp * 1000) - 300000) - now.getTime();
      if (checkValidityOfToken(userAuthToken) ===  'LOGIN') {
        if (viewType === 'esignView') {
          return navigateToPage(navigatingUrl)
        }
        return navigateToPage('/login')
      } else {
        setTimeout(() => {
          const resp = checkValidityOfToken(userAuthToken)
          if (resp ===  'LOGIN') {
            if (viewType === 'esignView') {
              return navigateToPage(navigatingUrl)
            }
            return navigateToPage('/login')
          } else if (resp === 'REGENERATE' && viewType !== 'esignView') {
            regenerateToken()
          }
        }, timeLeft)
      }
    }
  }, [userAuthToken])

  return (
    <div className="App flex">
      {showGenericLoading && <LoadingComponent />}
      <Router>
        <TopNavbar />
        {role === 'USER' && <Sidebar />}
        <Switch>
          <Route exact path='/' element={<HomePage />} />
          <Route exact path='/login' element={<Login />} />
          <Route exact path='/uploadDocument/:id' element={<UploadDocument />} />
          <Route exact path='/documentViewer' element={<DocViewer />} />
          <Route exact path='/documents' element={<Documents />} />
          <Route exact path='/documents/:id' element={<DocumentDetails />} />
          <Route exact path='/pdfeditor' element={<PDFEditor />} />
          <Route exact path='/createUser/:id' element={<CreateUser />} />
          <Route exact path='/manageUser' element={<ManageUser />} />
          <Route exact path='/createAdmin' element={<CreateAdmin />} />
          <Route exact path='/manageAdmin' element={<ManageAdmin />} />
          <Route exact path='/validateEsign' element={<ValidateEsign />} />
          <Route exact path='/templates' element={<Templates />} />
          <Route exact path='/esignSuccess' element={<EsignSuccessfull />} />
          <Route exact path='/test' element={<Test />} />
        </Switch>
        <ToastContainer />
      </Router>
    </div>
  );
}

export default App;
