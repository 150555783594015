import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { superAdminAPI } from "../APIRoutes";
import { checkValidityOfToken } from "../utils/helper";
import { regenerateToken } from "./UserStore";

const initialState = {
    products: []
}

export const superAdminSlice = createSlice({
    name: 'superAdmin',
    initialState,
    reducers: {
        setProducts: (state, action) => {
            state.products = action.payload
        }
    }
})

export const { setProducts } = superAdminSlice.actions
export default superAdminSlice.reducer
