import React, { useEffect, useState } from 'react'
import Title from '../../components/generic/Title'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { showMessage } from '../../utils/helper'
import { postAPIRequest } from '../../utils/fetchAPIs'
import { adminAPI } from '../../APIRoutes'
import { ArrowPathIcon, ChevronDoubleLeftIcon, ChevronDoubleRightIcon, ChevronLeftIcon, ChevronRightIcon, EllipsisHorizontalIcon, FunnelIcon, MoonIcon, TrashIcon } from '@heroicons/react/24/outline'
import SelectBox from '../../components/generic/SelectBox'
import { LuLoader2 } from 'react-icons/lu'
import DateModal from '../../components/generic/DateModal'
import UserDetailsModal from '../../components/UserDetailsModal'

export const ManageUser = () => {
    const navigate = useNavigate()
    const userAuthToken = useSelector(state => state.user.userToken)
    const [allUsers, setAllUsers] = useState([])
    const [currentPage, setCurrentPage] = useState(1)
    const [totalPages, setTotalPages] = useState(0)
    const [pageData, setPageData] = useState(10)
    const [totalUsers, setTotalUsers] = useState(0)
    const [loadingUsers, setLoadingUsers] = useState(false)
    const [filterOpen, setFilterOpen] = useState(false)
    const [filterList, setFilterList] = useState({
        name: '',
        email: '',
        companyName: '',
        dateFrom: '',
        dateTo: ''
    })
    const [reset, setReset] = useState(false)
    const [showDateModal, setShowDateModal] = useState(false)
    const [showUserModal, setShowUserModal] = useState(false)
    const [selectedUser, setSelectedUser] = useState()


    function changePageNo(type) {
        if (type === 'prev') {
            if (currentPage > 1) {
                setCurrentPage(currentPage - 1)
            }
        }
        if (type === 'next') {
            if (currentPage < totalPages) {
                setCurrentPage(currentPage + 1)
            }
        }
    }

    function resetFilter() {
        setReset(true)
        setFilterList({
            name: '',
            email: '',
            companyName: '',
            dateFrom: '',
            dateTo: ''
        });
        getAllUsersData({
            documentName: '',
            documentId: '',
            recipientEmailId: '',
            status: '',
            dateFrom: '',
            dateTo: '',
            pageData: pageData,
            pageNo: 0
        }); 
        setCurrentPage(1)
    }

    function handlePageLoad(data) {
        setPageData(data.name)
    }

    function handleChangePageNo(e) {
        if (e.target.value > totalPages) return setCurrentPage(totalPages);
        setCurrentPage(e.target.value)
    }

    function convertDateIntoStandardFormat(date) {
        const currentDate = new Date(date)
        return `${String(currentDate.getDate()).padStart(2, '0')}-${String(currentDate.getMonth()).padStart(2, '0')}-${currentDate.getFullYear()}`;
    }

    function getDateDaysBefore(days) {
        // Create a new Date object for the current date
        const currentDate = new Date();
    
        // Subtract the specified number of days from the current date
        currentDate.setDate(currentDate.getDate() - days);
    
        return currentDate;
    }

    function updateDateFilter(value) {
        if (value.name === 'Custom') {
            setShowDateModal(true)
        }
        else {
            let dateFrom = ''
            if (value.id == 1) {
                dateFrom = getDateDaysBefore(30)
            } else {
                dateFrom = getDateDaysBefore(7)
            }
            const currentDate = getDateDaysBefore(0)
            setFilterList({
                ...filterList,
                dateFrom: convertDateIntoStandardFormat(dateFrom),
                dateTo: convertDateIntoStandardFormat(currentDate)
            })
        } 
    }

    function updateDateFilterFromModal(dates) {
        setFilterList({
            ...filterList,
            dateFrom: convertDateIntoStandardFormat(dates.startdate),
            dateTo: convertDateIntoStandardFormat(dates.enddate)
        })
    }

    async function getAllUsersData() {
        try {
            setLoadingUsers(true)
            const body = {
                "name": "Rahul",
                "createdDateFrom": "",
                "createdDateTo": "",
                "companyName": "",
                "email": ""
            }
            const headers = {
                'AuthToken': userAuthToken
            }
            const allUsersResponse = await postAPIRequest(`${adminAPI}/getAllUsers?pageNo=${currentPage - 1}&dateSize=${pageData}`, body, headers)
            setAllUsers(allUsersResponse.response.userList)
            setTotalPages(allUsersResponse.response.totalPageNo)
            setTotalUsers(allUsersResponse.response.totalDataSize)
        } catch (error) {
            showMessage(error.response.data.Response.response, 'error')
        } finally {
            setLoadingUsers(false)
        }
    }

    useEffect(() => {
        if (userAuthToken) getAllUsersData()
    }, [userAuthToken, currentPage, pageData])

    return (
        <div className='my-16 h-full w-full'>
            {showDateModal && <DateModal selectedDate={updateDateFilterFromModal} closeModal={() => setShowDateModal(false)} />}
            {showUserModal && <UserDetailsModal closeModal={() => setShowUserModal(false)} userDetails={selectedUser} />}
            <Title title="All Users" />
            {allUsers.length === 0 && <div className='px-16 mt-16 flex items-end justify-end'>
                <div onClick={() => navigate('/createUser')} className='bg-blue-800 hover:bg-blue-900 w-fit cursor-pointer py-3 px-4 rounded-lg text-white'>Create User</div>
            </div>}
            <div className='w-full px-16 mt-6'>
                {
                    allUsers.length > 0 ? (
                        <div>
                            <div className='flex px-16 mt-16 justify-between items-center'>
                                <div className='flex space-x-5 justify-center items-center'>
                                    <div>
                                        {`View ${allUsers.length ? ((currentPage - 1) * pageData) + 1 : 0} - ${currentPage * pageData > totalUsers ? totalUsers : currentPage * pageData} of ${totalUsers}`}
                                    </div>
                                    <div className='flex justify-center items-center space-x-5'>
                                        <span>Show</span>
                                        <SelectBox list={[{ id: 0, name: '10' }, { id: 1, name: '20' }, { id: 2, name: '30' }]} updateSelectedValue={handlePageLoad} />
                                    </div>
                                    <TrashIcon className='w-5 h-5' />
                                    <ArrowPathIcon className={`${loadingUsers && 'animate-spin'} w-5 h-5 cursor-pointer hover:scale-110 hover:ease-in-out`} onClick={() => { !loadingUsers && getAllUsersData(); setCurrentPage(1) }} />
                                </div>
                                <div className='flex space-x-5 items-center'>
                                    <ChevronDoubleLeftIcon className={`${currentPage === 1 && '!cursor-not-allowed opacity-50'} w-5 h-5 cursor-pointer`} onClick={() => setCurrentPage(1)} />
                                    <ChevronLeftIcon className={`${currentPage === 1 && '!cursor-not-allowed opacity-50'} w-5 h-5 cursor-pointer`} onClick={() => changePageNo('prev')} />
                                    <input type="number" name="pageNo" value={currentPage} onChange={(e) => handleChangePageNo(e)} className='w-12 h-8 flex text-center border rounded-md focus:outline-none' />
                                    <ChevronRightIcon className={`${currentPage === totalPages && '!cursor-not-allowed opacity-50'} w-5 h-5 cursor-pointer`} onClick={() => changePageNo('next')} />
                                    <ChevronDoubleRightIcon className={`${currentPage === totalPages && '!cursor-not-allowed opacity-50'} w-5 h-5 cursor-pointer`} onClick={() => setCurrentPage(totalPages)} />
                                    <span>|</span>
                                    <FunnelIcon className='w-5 h-5 cursor-pointer' onClick={() => { setFilterOpen(!filterOpen); }} />
                                    <div onClick={() => navigate('/createUser/new')} className='bg-blue-800 hover:bg-blue-900 w-fit cursor-pointer py-3 px-4 rounded-lg text-white'>Create User</div>
                                </div>
                            </div>
                            <div className='mx-16 mt-5 border rounded-lg'>
                                <div className='border-b bg-gray-200'>
                                    <div className='flex w-[100%] px-4 py-3 gap-x-4 font-semibold text-gray-800 text-base'>
                                        <span className='w-[5%] flex items-center'>S.No.</span>
                                        <span className='w-[20%] flex items-center'>Name</span>
                                        <span className='w-[25%] flex items-center'>Email</span>
                                        <span className='w-[20%] flex items-center'>Company Name</span>
                                        <span className='w-[20%] flex'>Created On</span>
                                        <span className='w-[10%] flex' />
                                        </div>
                                    {filterOpen && <div className='flex w-[100%] relative px-4 py-3 gap-x-4 font-semibold text-gray-800 text-base'>
                                        <span className='w-[5%] flex' />
                                        <span className='w-[20%] flex'>
                                            <input type="text" name="name" onChange={(e) => { setFilterList({ ...filterList, [e.target.name]: e.target.value }) }} value={filterList.documentName} className='w-full rounded-lg h-[38px] focus:outline-none px-1.5 font-normal border border-gray-300' />
                                        </span>
                                        <span className='w-[25%] flex'>
                                            <input type="text" name="email" onChange={(e) => { setFilterList({ ...filterList, [e.target.name]: e.target.value }) }} value={filterList.documentId} className='w-full rounded-lg h-[38px] focus:outline-none px-1.5 font-normal border border-gray-300' />
                                        </span>
                                        <span className='w-[20%] flex'>
                                            <input type="text" name="companyName" onChange={(e) => { setFilterList({ ...filterList, [e.target.name]: e.target.value }) }} value={filterList.recipientEmailId} className='w-full rounded-lg h-[38px] focus:outline-none px-1.5 font-normal border border-gray-300' />
                                        </span>
                                        <span className='w-[20%] flex'>
                                            <SelectBox resetValue={reset} list={['--select--', '7 days', '30 days', 'Custom'].map((status, index) => ({ id: index, name: status }))} updateSelectedValue={(value) => { value.id === 0 ? setReset(false) : updateDateFilter(value) }} valueResetted={() => setReset(false)} />
                                        </span>
                                        <button className='w-[10%] flex border-2 bg-green-600 rounded-lg hover:bg-green-700/80 justify-center items-center text-white ' onClick={() => getAllUsersData()}>Filter</button>
                                        <span className='absolute text-indigo-500 cursor-pointer right-7 underline hover:text-indigo-700 text-sm -top-2' onClick={() => { resetFilter(); }}>Clear Filter</span>
                                    </div>}
                                </div>
                                <div>
                                    {
                                        loadingUsers ? <div className='flex justify-center space-x-3 items-center text-3xl font-semibold text-gray-400 h-96'>
                                            <LuLoader2 className='w-8 h-8 animate-spin' />
                                            <span>
                                                Fetching Users
                                            </span>
                                        </div> : <div className='divide-y divide-gray-300'>

                                            {
                                                allUsers.map((user, index) => {
                                                    return (

                                                        <div key={user.userUuid} onClick={() => {setSelectedUser(user); setShowUserModal(true);}} className='flex w-[100%] text-gray-800 cursor-pointer font-medium px-4 gap-x-4 text-sm min-h-12 py-3.5 hover:bg-gray-100'>
                                                            <span className='w-[5%] flex'>{((currentPage - 1) * pageData) + index + 1}</span>
                                                            <span className='w-[20%] flex'>{user.name}</span>
                                                            <span className='w-[25%] flex'>{user.loginDetails.emailId}</span>
                                                            <span className='w-[20%] flex'>{user.userCompanyDetails?.companyName}</span>
                                                            <span className='w-[20%] flex'>{new Date(user.loginDetails.createDate).toLocaleDateString()}</span>
                                                            <span className='w-[10%] pl-5 flex' />
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>)
                        : <div className='border rounded-lg h-full flex items-center justify-center'>
                            No Users found!
                        </div>
                }
            </div>
        </div>
    )
}
