import { Button, Dialog, DialogPanel, DialogTitle, DialogBackdrop } from '@headlessui/react'
import { useState } from 'react'
import { XMarkIcon, PencilIcon } from '@heroicons/react/24/outline'
import { useNavigate } from 'react-router-dom'

export default function UserDetailsModal({ closeModal, userDetails }) {
    const [isOpen, setIsOpen] = useState(true)
    const navigate = useNavigate()

    function close() {
        setIsOpen(false)
    }

    function handleSaveModal() {
        navigate(`/createUser/${userDetails.userUuid}`)
    }

    return (
        <>
            <Dialog open={isOpen} as="div" className="relative z-50 focus:outline-none" onClose={close}>
                {/* The backdrop, rendered as a fixed sibling to the panel container */}
                <DialogBackdrop className="fixed inset-0 bg-black/50" />
                <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                    <div className="flex min-h-full items-center justify-center p-4">
                        <DialogPanel
                            transition
                            className="w-full max-w-4xl px-10 rounded-xl bg-white p-6 backdrop-blur-2xl duration-300 ease-out data-[closed]:transform-[scale(95%)] data-[closed]:opacity-0"
                        >
                            <div className='flex items-end justify-end'>
                                <XMarkIcon onClick={() => closeModal()} className='text-red-500 w-6 h-6 cursor-pointer hover:text-red-600' />
                            </div>
                            <div className='flex justify-between items-end mb-8'>
                                <DialogTitle as="h3" className="text-xl font-semibold">
                                    User Details
                                </DialogTitle>
                            </div>

                            <div className='flex flex-col px-5'>
                                <span className='text-xl font-medium mb-3'>Personal Details</span>
                                <div className='grid grid-cols-2 px-2 gap-y-5'>
                                    <div className='flex flex-col'>
                                        <span className='font-medium text-gray-800'>Name</span>
                                        <span className='text-gray-800'>{userDetails.name}</span>
                                    </div>
                                    <div className='flex flex-col'>
                                        <span className='font-medium text-gray-800'>User name</span>
                                        <span className='text-gray-800'>{userDetails.username}</span>
                                    </div>
                                    <div className='flex flex-col'>
                                        <span className='font-medium text-gray-800'>Created By</span>
                                        <span className='text-gray-800'>{userDetails.createdBy}</span>
                                    </div>
                                    <div className='flex flex-col'>
                                        <span className='font-medium text-gray-800'>Created Date</span>
                                        <span className='text-gray-800'>{new Date(userDetails.loginDetails.createDate).toLocaleDateString()}</span>
                                    </div>
                                </div>
                                <span className='text-xl font-medium mb-3 mt-5'>Company Details</span>
                                <div className='grid grid-cols-2 px-2 gap-y-5'>
                                    <div className='flex flex-col'>
                                        <span className='font-medium text-gray-800'>Company Name</span>
                                        <span className='text-gray-800'>{userDetails.userCompanyDetails && userDetails.userCompanyDetails.companyName}</span>
                                    </div>
                                    <div className='flex flex-col'>
                                        <span className='font-medium text-gray-800'>GST No</span>
                                        <span className='text-gray-800'>{userDetails.userCompanyDetails && userDetails.userCompanyDetails.gstNo}</span>
                                    </div>
                                    <div className='flex flex-col'>
                                        <span className='font-medium text-gray-800'>Pan No</span>
                                        <span className='text-gray-800'>{userDetails.userCompanyDetails && userDetails.userCompanyDetails.panNo}</span>
                                    </div>
                                    <div className='flex flex-col'>
                                        <span className='font-medium text-gray-800'>Created By</span>
                                        <span className='text-gray-800'>{userDetails.userCompanyDetails && userDetails.userCompanyDetails.createdBy}</span>
                                    </div>
                                </div>
                                <span className='text-xl font-medium mb-3 mt-5'>Person of Contact Details</span>
                                <div className='grid grid-cols-2 px-2 gap-y-5'>{userDetails.userPocDetails && userDetails.userPocDetails.map((poc) => {
                                    return (
                                        <div>
                                            <div className='flex flex-col'>
                                                <span className='font-medium text-gray-800'>Name</span>
                                                <span className='text-gray-800'>{}</span>
                                            </div>
                                            <div className='flex flex-col'>
                                                <span className='font-medium text-gray-800'>Email</span>
                                                <span className='text-gray-800'>{}</span>
                                            </div>
                                        </div>        
                                    )
                                })}
                                </div>
                                <span className='text-xl font-medium mb-3 mt-5'>Products</span>
                                <div className='grid grid-cols-3 px-2 gap-y-5'>
                                    {userDetails.userProducts && userDetails.userProducts.map((product, index) => {
                                        return (
                                            <div key={index} className='flex space-x-2'>
                                                <span className=' text-gray-800'>{`${index+1}. `}</span>
                                                <span className='text-gray-800'>{` ${product.productId}`}</span>
                                            </div>
                                        )
                                    })}
                                </div>
                                <span className='text-xl font-medium mb-3 mt-5'>Permissions</span>
                                <div className='grid grid-cols-3 px-2 gap-y-5'>
                                {userDetails.userPermission && userDetails.userPermission.map((product, index) => {
                                        return (
                                            <div key={index} className='flex space-x-2'>
                                                <span className=' text-gray-800'>{`${index+1}. `}</span>
                                                <span className='text-gray-800'>{` ${product.permissionName}`}</span>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>

                            <div className="mt-12 mb-8 flex justify-end">
                                <Button
                                    className="inline-flex items-center gap-2 rounded-md bg-gray-700 py-1.5 px-3 text-sm/6 font-semibold text-white shadow-inner shadow-white/10 focus:outline-none data-[hover]:bg-gray-600 data-[focus]:outline-1 data-[focus]:outline-white data-[open]:bg-gray-700"
                                    onClick={() => handleSaveModal()}
                                >
                                    <span>Edit</span>
                                    <PencilIcon className='w-4 h-4' />
                                </Button>
                            </div>
                        </DialogPanel>
                    </div>
                </div>
            </Dialog>
        </>
    )
}
