import { Fragment, useRef, useState } from 'react'
import { Dialog, Transition, TransitionChild, DialogTitle, DialogPanel } from '@headlessui/react'
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline'
import { showMessage } from '../../utils/helper'

export default function DateModal({ selectedDate, closeModal }) {
    const [open, setOpen] = useState(true)
    const [dateRange, setDateRange] = useState({ startdate: '', enddate: '' })

    const handleDateChange = (event) => {
        setDateRange({...dateRange, [event.target.name]: event.target.value})
    }

    const validateDateRange = () => {
        // Dates cannot be empty
        if (!dateRange.startdate || !dateRange.enddate) return showMessage('Please select both dates', 'error')

        // End date should be greater than Start date
        if (new Date(dateRange.startdate) > new Date(dateRange.enddate) ) return showMessage('Start Date cannot be greater than end date', 'error')

        // End date should not be greater than today's date 
        if (new Date(dateRange.enddate).toDateString() !== new Date().toDateString() && new Date(dateRange.enddate).getTime() > new Date().getTime()) return showMessage("End date cannot be greater than today's date", 'error')

        selectedDate(dateRange)
        closeModal()
    }

    const cancelButtonRef = useRef()

    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={() => { setOpen(); closeModal() }}>
                {/* <Dialog.Overlay className="fixed inset-0 bg-black opacity-50" /> */}
                <TransitionChild
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </TransitionChild>

                <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                    <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center">
                        <TransitionChild
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <DialogPanel className="relative transform overflow-hidden rounded-2xl bg-white text-left shadow-xl transition-all">
                                <div className="bg-white p-4 pt-10 px-20">
                                    <div className="flex w-[100%]">
                                        <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-blue-100 sm:mx-0 sm:h-10 sm:w-10">
                                            <ExclamationTriangleIcon className="h-6 w-6 text-blue-600" aria-hidden="true" />
                                        </div>
                                        <div className="mt-3 text-center sm:ml-4 sm:mt-1.5 sm:text-left">
                                            <DialogTitle as="h3" className="text-2xl font-semibold leading-6 text-gray-900">
                                                Select Date Range
                                            </DialogTitle>
                                            <div className='my-10 flex space-x-10'>
                                                <div className='flex flex-col space-y-0.5 text-lg'>
                                                    <label htmlFor="startdate">Start Date:</label>
                                                    <input type="date" name="startdate" id="startdate" value={dateRange.startdate} onChange={handleDateChange} className='border-2 border-gray-300 px-4 py-2 rounded-lg'/>
                                                </div>
                                                <div className='flex flex-col space-y-0.5 text-lg'>
                                                    <label htmlFor="enddate">End Date:</label>
                                                    <input type="date" name="enddate" id="enddate" value={dateRange.enddate} onChange={handleDateChange} className='border-2 border-gray-300 px-4 py-2 rounded-lg'/>
                                                </div>
                                            </div>
                                            <div className='mb-3 flex items-end justify-end space-x-6'>
                                                <button className='border-2 px-4 py-2 rounded-lg bg-red-500 text-white border-red-500 hover:bg-red-600 hover:border-red-600' onClick={() => closeModal()}>
                                                    Cancel
                                                </button>
                                                <button className='border-2 px-4 py-2 rounded-lg bg-blue-500 text-white border-blue-500 hover:bg-blue-600 hover:border-blue-600' onClick={() => validateDateRange()}>
                                                    Filter Data
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </DialogPanel>
                        </TransitionChild>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    )
}