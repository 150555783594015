import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { fetchDocument } from '../stores/DocumentSlice'
import { showMessage } from '../utils/helper'
import { ArrowDownTrayIcon, ArrowLeftIcon, Cog8ToothIcon, CheckIcon, ClipboardDocumentListIcon, ClockIcon, DocumentTextIcon, EllipsisHorizontalIcon, EnvelopeIcon, EyeIcon, MoonIcon, PencilIcon, PrinterIcon, TrashIcon } from '@heroicons/react/24/outline'
import { esignAPI } from '../APIRoutes'
import { Document, Page, pdfjs } from 'react-pdf'
import { postAPIRequest } from '../utils/fetchAPIs'
import { LuLoader2 } from 'react-icons/lu'
import PopOver from '../components/generic/PopOver'

export const DocumentDetails = () => {

    const navigate = useNavigate()
    const location = useLocation()
    const document = useSelector((state) => state.document.currentDocument)
    const userAuthToken = useSelector(state => state.user.userToken)
    const [currentDocument, setCurrentDocument] = useState({})
    const [recipients, setRecipients] = useState([])
    const [url, setUrl] = useState('')
    const [totalPages, settotalPages] = useState(0)

    const solutions = [
        { name: 'Reminder Settings', href: '#', icon: Cog8ToothIcon },
        { name: 'Email Document', href: '#', icon: EnvelopeIcon },
        { name: 'Download', href: '#', icon: ArrowDownTrayIcon },
        { name: 'Edit as new', href: '#', icon: ClipboardDocumentListIcon },
        { name: 'Print', href: '#', icon: PrinterIcon },
        { name: 'Delete', href: '#', icon: TrashIcon },
    ]

    /**
      * Function to get documents list from server
    */
    async function getDocument(docId) {
        const body = {
            "dateFrom": "",
            "dateTo": "",
            "docId": docId,
            "documentName": "",
            "recipientEmailId": "",
            "status": "",
            "pageNo": "0",
            "pageData": "10"
        }
        const headers = {
            AuthToken: userAuthToken,
            'Content-Type': 'application/json'
        }
        try {
            const getAllDocumentResponse = await postAPIRequest(`${esignAPI}/getDocuments`, body, headers)
            const fetchedDocument = getAllDocumentResponse.response.data[0]
            const fetchedUrl = await fetchDocument(fetchedDocument.documentId, userAuthToken)
            setUrl(fetchedUrl[0])
            setCurrentDocument(fetchedDocument)
            setRecipientData(fetchedDocument.esignSettingsEntity.esignReciptants)
        } catch (error) {
            showMessage(error.response.data.Response.response, 'error')
        }
    }

    /**
 *
 * @param {Object} event
 *
 * This function will be called when pdf is loaded
 */
    function onDocLoad(event) {
        settotalPages(event.numPages);
    }

    useEffect(() => {
        async function getDocumentData() {
            if (!document) {
                const docId = location.pathname.split('/documents/')[1]
                if (docId && userAuthToken) {
                    getDocument(docId)
                }
            } else {
                const fetchedUrl = await fetchDocument(document.documentId, userAuthToken)
                setUrl(fetchedUrl[0])
                setCurrentDocument(document)
                setRecipientData(document.esignSettingsEntity.esignReciptants)
            }
        }
        getDocumentData()
    }, [document, userAuthToken])

    function setRecipientData(recipientData) {
        const dt = recipientData.map((recipeint) => {
            let ad = [
                { name: 'Mailed', status: 'current' },
                { name: 'Viewed', status: 'upcoming' },
                { name: 'Signed', status: 'upcoming' },
            ]
            if (recipeint.signedAt) {
                ad = [
                    { name: 'Mailed', status: 'complete' },
                    { name: 'Viewed', status: 'complete' },
                    { name: 'Signed', status: 'complete' },
                ]
            } else if (recipeint.viewedAt) {
                ad = [
                    { name: 'Mailed', status: 'complete' },
                    { name: 'Viewed', status: 'complete' },
                    { name: 'Signed', status: 'current' },
                ]
            } else if (recipeint.sentForEsignAt) {
                ad = [
                    { name: 'Mailed', status: 'complete' },
                    { name: 'Viewed', status: 'current' },
                    { name: 'Signed', status: 'upcoming' },
                ]
            }
            return {
                ...recipeint,
                barStatus: ad
            }
        })
        setRecipients([...dt])
    }

    useEffect(() => {
        // On load adding pdfjs worker
        pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

    }, [totalPages])

    return (
        <div className='flex flex-col justify-end items-end w-full'>
            <div className='mt-16 w-[79%]'>
                {/* Menu Bar */}
                <div className='flex bg-gray-100 w-full h-12 space-x-5 items-center px-8'>
                    <span><ArrowLeftIcon className='w-5 h-5 cursor-pointer' onClick={() => navigate('/documents')} /></span>
                    <div className='flex space-x-1 items-center justify-center cursor-pointer' onClick={() => navigate(`/documentViewer?documentId=${currentDocument.documentId}&viewType=view&uploadType=document`)}>
                        <DocumentTextIcon className='w-5 h-5' />
                        <span>View Document</span>
                    </div>
                    <div className='flex items-center justify-center space-x-1'>
                        <PencilIcon className='w-4 h-4' />
                        <span>Edit</span>
                    </div>
                    {currentDocument.status !== 'COMPLETE' && <div className='flex items-center justify-center space-x-1'>
                        <MoonIcon className='w-4 h-4' />
                        <span>Extend</span>
                    </div>}
                    <div className='flex items-center justify-center space-x-1'>
                        <EnvelopeIcon className='w-4 h-4' />
                        <span>Email Document</span>
                    </div>
                    <div className='flex items-center justify-center space-x-1'>
                        <ArrowDownTrayIcon className='w-4 h-4' />
                        <span>Download</span>
                    </div>
                    <div className='flex items-center justify-center space-x-1'>
                        <ClockIcon className='w-5 h-5' />
                        <span>Send Reminder</span>
                    </div>
                    <div className='flex items-center justify-center'>
                        <PopOver buttonText={<EllipsisHorizontalIcon className='w-5 h-5' />} solutions={solutions} />
                    </div>
                </div>

                {/* Document details section */}
                <div className='flex justify-between px-8 mt-8'>
                    <div className='flex space-x-10'>
                        <div>
                            <Document
                                loading={<span className='flex items-center justify-center'><LuLoader2 className='w-5 h-5 animate-spin' /></span>}
                                className={
                                    "flex flex-col justify-start items-center overflow-hidden h-full"
                                }
                                file={url}
                                onLoadSuccess={onDocLoad}
                            >
                                <div>
                                    <Page height={150} pageNumber={1}>
                                        <div className='absolute h-[150px] bg-black/70 flex items-end w-full z-10 justify-center border top-0'>
                                            <span onClick={() => navigate(`/documentViewer?documentId=${currentDocument.documentId}&viewType=view&uploadType=document`)} className='bg-green-600 cursor-pointer flex items-center justify-center space-x-2 text-white w-full'>
                                                <EyeIcon className='w-5 h-5 text-white' />
                                                <span>View</span>
                                            </span>
                                        </div>
                                    </Page>
                                </div>
                            </Document>
                        </div>
                        <div className='flex flex-col items-start space-y-3'>
                            <span className='font-medium'>{currentDocument.documentName}</span>
                            <span>Description</span>
                            <span>Submitted on</span>
                            <span>Expiring On {currentDocument.esignSettingsEntity && new Date(currentDocument.esignSettingsEntity.esignExpiry).toLocaleDateString()}</span>
                        </div>
                    </div>
                    <div>{currentDocument.status}</div>
                </div>

                {/* Recipient Details */}
                <div className='px-8 mt-8'>
                    <span className='flex items-start font-semibold text-gray-800 mb-5'>Recipient status</span>
                    <div className='flex flex-col gap-y-5'>
                        {
                            recipients.map((recipient, index) => {
                                return (
                                    <div key={recipient.recipientId} className='border min-h-24 border-l-8 border-l-green-100'>
                                        <div className='grid grid-cols-12 py-4 min-h-24'>
                                            <span className='flex items-center justify-center col-span-1 border-r'>{index + 1}</span>
                                            <div className='flex flex-col items-start justify-center col-span-7 px-3'>
                                                <span>{recipient.name}</span>
                                                <span>{recipient.email}</span>
                                            </div>
                                            <div className='col-span-4'>
                                                <nav aria-label="Progress">
                                                    <ol role="list" className="flex items-center justify-center">
                                                        {recipient.barStatus && recipient.barStatus.map((step, stepIdx) => (
                                                            <li
                                                                key={step.name}
                                                                className={`relative ${stepIdx !== recipient.barStatus.length - 1 ? "pr-8 sm:pr-20" : ""
                                                                    }`}
                                                            >
                                                                {step.status === "complete" ? (
                                                                    <>
                                                                        <div className="absolute inset-0 flex items-center" aria-hidden="true">
                                                                            <div className="h-0.5 w-full bg-indigo-600" />
                                                                        </div>
                                                                        <a
                                                                            className="relative w-8 h-8 flex items-center justify-center bg-indigo-600 rounded-full hover:bg-indigo-900"
                                                                        >
                                                                            <CheckIcon className="w-5 h-5 text-white" aria-hidden="true" />
                                                                        </a>
                                                                        <span className="absolute -left-3">{step.name}</span>
                                                                    </>
                                                                ) : step.status === "current" ? (
                                                                    <>
                                                                        <div className="absolute inset-0 flex items-center" aria-hidden="true">
                                                                            <div className="h-0.5 w-full bg-gray-200" />
                                                                        </div>
                                                                        <a
                                                                            className="relative w-8 h-8 flex items-center justify-center bg-white border-2 border-indigo-600 rounded-full"
                                                                            aria-current="step"
                                                                        >
                                                                            <span className="h-2.5 w-2.5 bg-indigo-600 rounded-full" aria-hidden="true" />
                                                                        </a>
                                                                        <span className="absolute -left-3">{step.name}</span>
                                                                    </>
                                                                ) : (
                                                                    <>
                                                                        <div className="absolute inset-0 flex items-center" aria-hidden="true">
                                                                            <div className="h-0.5 w-full bg-gray-200" />
                                                                        </div>
                                                                        <a
                                                                            className="group relative w-8 h-8 flex flex-col items-center justify-center bg-white border-2 border-gray-300 rounded-full hover:border-gray-400"
                                                                        >
                                                                            <span
                                                                                className="h-2.5 w-2.5 bg-transparent rounded-full group-hover:bg-gray-300"
                                                                                aria-hidden="true"
                                                                            />
                                                                        </a>
                                                                        <span className="absolute -left-3">{step.name}</span>
                                                                    </>
                                                                )}
                                                            </li>
                                                        ))}
                                                    </ol>
                                                </nav>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div >
                </div >
            </div >
        </div>
    )
}
