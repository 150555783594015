import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    products: []
}

export const adminSlice = createSlice({
    name: 'admin',
    initialState,
    reducers: {
        setProducts: (state, action) => {
            state.products = action.payload
        }
    }
})

export const { setProducts } = adminSlice.actions
export default adminSlice.reducer
