import { jwtDecode } from 'jwt-decode';
import { toast } from "react-toastify"

export const showMessage = (message, type) => {
    if (type === 'error') {
        toast.error(message, {
            position: "bottom-right",
            autoClose: 8000,
            draggable: true,
            theme: 'colored',
            pauseOnHover: true,
        });
        return;
    }
    toast.success(message, {
        position: "bottom-right",
        autoClose: 8000,
        draggable: true,
        theme: 'colored',
        pauseOnHover: true,
    });
}

export function checkValidityOfToken(token) {
    if (!token) return "LOGIN";

    // Decode the token without verifying the signature
    const decoded = jwtDecode(token.toString());

    // Check if the token has an "exp" claim
    if (decoded && decoded.exp) {
        const currentTime = Math.floor(Date.now() / 1000); // Current time in seconds
    
        if (decoded.exp < currentTime) {
            return "LOGIN"
        } else if (decoded.exp - 300 < currentTime) {
            return "REGENERATE"
        } else {
            return "VALID"
        }
    } else {
        return "LOGIN"
    }
}

export function decodeJWT(token) {
    return jwtDecode(token.toString())
}

export function splitByCapitalLetters(str) {
    let splittedStrings = ''
    str.split(/(?=[A-Z])/).forEach((s) => {
        splittedStrings += s + ' '
    });
    return (splittedStrings.charAt(0).toUpperCase() + splittedStrings.slice(1)).trim()
}
