import React, { useEffect } from 'react'
import Title from '../../components/generic/Title'
import UserDetails from '../../components/admin/UserDetails'
import { useLocation } from 'react-router-dom'

const CreateUser = () => {

  const location = useLocation()

  useEffect(() => {
    console.log(location)
  }, [])

  return (
    <div className='my-16 w-full h-full'>
      <Title title="Create User" />
      <UserDetails />
    </div>
  )
}

export default CreateUser
