import React from 'react';
import { Tooltip } from 'react-tooltip'

export const Test = () => (
  <div className='mt-52'>
    <a data-tooltip-id="my-tooltip" data-tooltip-content="Hello world!">
  ◕‿‿◕
</a>
<a data-tooltip-id="my-tooltip" data-tooltip-content="Rahul Goel!">
  ◕‿‿◕
</a>
<Tooltip id="my-tooltip" place='bottom' />
  </div>
);
