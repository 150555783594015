import axios from "axios"
import { checkValidityOfToken } from "./helper"
import { host } from "../APIRoutes"
import { navigateToPage } from "./navigation"
import { store } from "../store"
import { setLoading, setToken } from "../stores/UserStore"
import Cookies from "js-cookie";

export const getAPIRequest = async (url, headers, authentication=true, allowRegenrating=true) => {
    try {
        store.dispatch(setLoading(true))
        const viewType = store.getState().document.viewType
        const navigatingUrl = store.getState().document.navigatingUrl
        if (authentication) {
            const validatedToken = checkValidityOfToken(headers.AuthToken)
            if (validatedToken === 'REGENERATE' && allowRegenrating) {
                const regeneratedToken = await regenerateToken()
                headers.AuthToken = regeneratedToken
            } else if (validatedToken === 'LOGIN') {
                if (viewType === 'esignView') {
                    return navigateToPage(navigatingUrl)
                }
                return navigateToPage('/login')
            }
        }
        let { data, status } = await axios.get(`${url}`, authentication && { headers })
        if (status === 200) {
            return data
        }
    } catch (error) {
        throw error
    } finally {
        store.dispatch(setLoading(false))
    }
}

export const postAPIRequest = async (url, postData, headers, authentication=true, allowRegenrating=true) => {
    try {
        
        store.dispatch(setLoading(true))
        const viewType = store.getState().document.viewType
        const navigatingUrl = store.getState().document.navigatingUrl
        if (authentication) {
            const validatedToken = checkValidityOfToken(headers.AuthToken)
            if (validatedToken === 'REGENERATE' && allowRegenrating) {
                const regeneratedToken = await regenerateToken()
                headers.AuthToken = regeneratedToken
            } else if (validatedToken === 'LOGIN') {
                if (viewType === 'esignView') {
                    return navigateToPage(navigatingUrl)
                }
                return navigateToPage('/login')
            }
        }
        const { data, status } = await axios.post(url, postData, authentication && { headers })
        if (status === 200) {
            return data
        }
    } catch (error) {
        throw error
    } finally {
        store.dispatch(setLoading(false))
    }
}

export const regenerateToken = async () => {
    try {
        const authToken = store.getState().user.userToken
        const viewType = store.getState().document.viewType
        const navigatingUrl = store.getState().document.navigatingUrl
        if (!authToken) {
            if (viewType === 'esignView') {
                return navigateToPage(navigatingUrl)
            }
            return navigateToPage('/login')
        }
        const headers = { 'AuthToken': authToken }
        const response = await axios.get(`${host}/regenerate-token`, { headers })
        if (response.data.ErrorMessage) {
            Cookies.remove('AuthToken')
            store.dispatch(setToken(''))
            if (viewType === 'esignView') {
                return navigateToPage(navigatingUrl)
            }
            return navigateToPage('/login');
        }
        else if (response.data.response['RegeneratedToken']) {
            const token = response.data.response['RegeneratedToken']
            store.dispatch(setToken(token))
            Cookies.set('AuthToken', token, { expires: 7 }); // Set a cookie with a 7-day expiration
            return token; 
        }
    } catch (error) {
        throw error
    }
}
