import React from 'react'
import CopyDocument from "../assets/icons/CopyDocument.png"
import PrepareIcon from "../assets/icons/PrepareIcon.png"
import SendIcon from "../assets/icons/SendIcon.png"
import { useNavigate } from 'react-router-dom'

export const HomePage = () => {
    const navigate = useNavigate()

  return (
    <div className='flex flex-col justify-end items-end w-full'>
        <div className='flex w-[79%] mt-12'>
            <div className='border-2 w-full m-16 border-dashed border-gray-400 space-y-20 rounded-xl flex flex-col h-[35rem] items-center justify-center'>
                <div className='grid grid-cols-3 items-center justify-center gap-x-40'>
                    <div className='flex flex-col w-32 items-center justify-center space-y-8'>
                        <img src={CopyDocument} alt="" className='w-24 h-24' />
                        <div className='flex flex-col space-y-2'>
                            <span className='text-lg font-semibold text-zinc-500/80'>
                                Document
                            </span>
                            <span className='text-gray-500/80'>
                                Upload or import your documents
                            </span>
                        </div>
                    </div>
                    <div className='flex flex-col w-32 items-center justify-center space-y-8'>
                        <img src={PrepareIcon} alt="" className='w-24 h-24' />
                        <div className='flex flex-col space-y-2'>
                            <span className='text-lg font-semibold text-zinc-500/80'>
                                Prepare
                            </span>
                            <span className='text-gray-500/80'>
                                Add fields you want filled out
                            </span>
                        </div>
                    </div>
                    <div className='flex flex-col w-32 items-center justify-center space-y-8'>
                        <img src={SendIcon} alt="" className='w-24 h-24' />
                        <div className='flex flex-col space-y-2'>
                            <span className='text-lg font-semibold text-zinc-500/80'>
                                Send
                            </span>
                            <span className='text-gray-500/80'>
                                Send documents for signing
                            </span>
                        </div>
                    </div>
                </div>
                <div>
                    <button className='text-blue-900 text-lg font-medium border-2 py-3 px-4 rounded-lg border-blue-900 hover:bg-blue-900 hover:text-white bg-white' onClick={() => navigate('/uploadDocument/new?type=document')}>
                        Get Document Signed
                    </button>
                </div>
            </div>
        </div>
    </div>
  )
}
