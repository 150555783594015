import { createSlice } from "@reduxjs/toolkit";
const initialState = {
    loggedIn: [],
    userToken: "",
    userRole: "",
    userPermissions: [],
    loading: false
}

export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setLoggedInStatus: (state, action) => {
            state.loggedIn = action.payload
        }, 
        setToken: (state, action) => {
            state.userToken = action.payload
        },
        setUserRole: (state, action) => {
            state.userRole = action.payload
        },
        setPermissions: (state, action) => {
            state.userPermissions = action.payload
        },
        setLoading: (state, action) => {
            state.loading = action.payload
        }
    }
})

export const { setLoggedInStatus, setToken, setUserRole, setPermissions, setLoading } = userSlice.actions
export default userSlice.reducer