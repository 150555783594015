import { Popover, PopoverButton, PopoverPanel } from '@headlessui/react'

export default function PopOver({buttonText, solutions}) {
  return (
    <Popover className="relative">
      <PopoverButton className="inline-flex items-center outline-none justify-center gap-x-1 text-sm/6 font-semibold text-gray-900">
        {buttonText}
      </PopoverButton>

      <PopoverPanel
        transition
        className="absolute left-1/2 z-10 mt-5 flex w-screen max-w-max -translate-x-1/4 px-4 transition data-[closed]:translate-y-1 data-[closed]:opacity-0 data-[enter]:duration-200 data-[leave]:duration-150 data-[enter]:ease-out data-[leave]:ease-in"
      >
        <div className="w-screen max-w-60 flex-auto overflow-hidden rounded-3xl bg-white text-sm/6 shadow-lg ring-1 ring-gray-900/5">
          <div className="p-1">
            {solutions.map((item) => (
              <div key={item.name} className="group cursor-pointer relative flex items-center justify-start gap-x-3 rounded-lg p-3 hover:bg-gray-100">
                <div className="mt-1 flex flex-none items-center justify-center rounded-lg group-hover:bg-white">
                  <item.icon aria-hidden="true" className="size-5 text-gray-600 group-hover:text-indigo-600" />
                </div>
                <div>
                  <a href={item.href} className="font-semibold text-gray-900">
                    {item.name}
                  </a>
                </div>
              </div>
            ))}
          </div>
        </div>
      </PopoverPanel>
    </Popover>
  )
}
