import { Dialog, DialogPanel, DialogTitle, TransitionChild, Transition } from '@headlessui/react'
import { useRef, useState, Fragment } from 'react'
import { TbTextRecognition } from "react-icons/tb";
import { MdDraw } from "react-icons/md";
import { ArrowUpTrayIcon } from '@heroicons/react/24/outline';
import DrawCanvas from '../generic/DrawCanvas';
import { PiPencilSimpleLineDuotone } from "react-icons/pi";
import { UploadArea } from '../generic/UploadArea';

export default function SignatureModal({ height, width, closeModal }) {
    const [open, setOpen] = useState(true)
    const [tabMenu, setTabMenu] = useState('text')
    const [selectedSignatureFont, setSelectedSignatureFont] = useState('prettyPen')
    const [signatureData, setSignatureData] = useState({ signature: '', initial: '' })
    const [selectedPencilColor, setSelectedPencilColor] = useState('black')
    const cancelButtonRef = useRef();
    const canvasRef = useRef(null);
    const textRef = useRef();

    const handleGenerateImage = () => {
        const canvas = textRef.current;
        if (!canvas) return;

        const ctx = canvas.getContext("2d");
        const padding = 10; // Optional padding
        let fontSize = 100; // Start with a large font size

        // Resize canvas
        canvas.width = width + padding * 2;
        canvas.height = height + padding * 2;

        // Adjust font size to fit within the canvas
        ctx.font = `${fontSize}px ${selectedSignatureFont}`;
        ctx.textAlign = "center";
        ctx.textBaseline = "middle";

        while (
            (ctx.measureText(signatureData.signature).width > width || fontSize * 1.2 > height) &&
            fontSize > 1
        ) {
            fontSize -= 1;
            ctx.font = `${fontSize}px ${selectedSignatureFont}`;
        }

        // Clear canvas and redraw with adjusted font size
        ctx.clearRect(0, 0, canvas.width, canvas.height);
        ctx.fillStyle = selectedPencilColor || "black"; // Default color if not provided
        ctx.fillText(signatureData.signature, canvas.width / 2, canvas.height / 2);

        // Generate image and download
        const image = canvas.toDataURL("image/png");
        return image
    };

    const handleUpload = () => {
        let imageURL = ''
        if (tabMenu === 'draw') {
            imageURL = convertToImage()
        }
        if (tabMenu === 'text') {
            // imageURL = {text: signatureData.signature, font: selectedSignatureFont}
            imageURL = handleGenerateImage()
        }
        // dispatch(updateSelectedSignatureImage({type: tabMenu ,img: imageURL}))
        closeModal({ type: tabMenu, img: imageURL });
    }

    const convertToImage = () => {
        if (canvasRef.current) {
            const canvas = canvasRef.current.getCanvas(); // Get canvas element from child
            const imageURL = canvas.toDataURL("image/png");

            return imageURL
        }
    };

    function onSignatureDataChange(event) {
        setSignatureData({ ...signatureData, [event.target.name]: event.target.value })
    }

    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog as="div" className="relative z-[10000]" initialFocus={cancelButtonRef} onClose={() => { setOpen('false'); closeModal() }}>
                <TransitionChild
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </TransitionChild>

                <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                    <div className="flex min-h-full justify-center text-center items-center p-0">
                        <TransitionChild
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-0 scale-95"
                            enterTo="opacity-100 translate-y-0 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 scale-100"
                            leaveTo="opacity-0 translate-y-0 scale-95"
                        >
                            <DialogPanel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-10 px-4 py-4 h-[35rem] w-[50rem] flex flex-col justify-between">
                                <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                                    <div className="flex w-[100%]">
                                        <DialogTitle as="h3" className="text-base flex font-semibold space-x-5 leading-6 text-gray-900">
                                            <div className={[tabMenu === 'text' ? 'cursor-pointer border-b-2 border-blue-900 h-20 w-24 flex justify-center items-center rounded-lg bg-indigo-200 text-blue-900' : 'h-20 cursor-pointer w-24 flex justify-center items-center rounded-lg bg-indigo-200 text-blue-900']} onClick={() => setTabMenu('text')}>
                                                <TbTextRecognition className='h-12 w-12' />
                                            </div>
                                            <div className={[tabMenu === 'draw' ? 'border-b-2 border-blue-900 h-20 w-24 cursor-pointer flex justify-center items-center rounded-lg bg-indigo-200 text-blue-900' : 'h-20 w-24 cursor-pointer flex justify-center items-center rounded-lg bg-indigo-200 text-blue-900']} onClick={() => setTabMenu('draw')}>
                                                <MdDraw className='h-12 w-12' />
                                            </div>
                                            <div className={[tabMenu === 'upload' ? 'border-b-2 border-blue-900 h-20 w-24 cursor-pointer flex justify-center items-center rounded-lg bg-indigo-200 text-blue-900' : 'h-20 w-24 cursor-pointer flex justify-center items-center rounded-lg bg-indigo-200 text-blue-900']} onClick={() => setTabMenu('upload')}>
                                                <ArrowUpTrayIcon className='h-12 w-12' />
                                            </div>
                                        </DialogTitle>
                                    </div>

                                    {/* Select Signature from different fonts */}
                                    {tabMenu === 'text' && <div>
                                        <div className='grid mt-7 grid-cols-12'>
                                            <canvas ref={textRef} style={{ display: "none" }} />
                                            <div className='flex flex-col col-span-8 mr-5'>
                                                <span className='p-2'>Signature</span>
                                                <input type="text" className='border py-2 px-4 rounded-lg' onChange={onSignatureDataChange} value={signatureData.signature} name="signature" />
                                            </div>
                                            <div className='flex flex-col col-span-4'>
                                                <span className='p-2'>Initial</span>
                                                <input type="text" className='border py-2 px-4 rounded-lg' onChange={onSignatureDataChange} value={signatureData.initial} name="initial" />
                                            </div>
                                            <div onClick={() => setSelectedSignatureFont('prettyPen')} className={`${selectedSignatureFont === 'prettyPen' && 'border-blue-900'} cursor-pointer col-span-12 text-5xl font-medium border-dashed border-2 tracking-wide font-prettyPen flex justify-between rounded-lg py-2 mt-3 px-4`}>
                                                <span>{signatureData.signature || 'Your Signature'}</span>
                                                <span>{signatureData.initial || 'Your Initial'}</span>
                                            </div>
                                            <div onClick={() => setSelectedSignatureFont('sweetlyBroken')} className={`${selectedSignatureFont === 'sweetlyBroken' && 'border-blue-900'} cursor-pointer col-span-12 border-2 text-5xl flex border-dashed font-sweetlyBroken justify-between rounded-lg py-2 font-medium tracking-wide mt-3 px-4`}>
                                                <span>{signatureData.signature || 'Your Signature'}</span>
                                                <span>{signatureData.initial || 'Your Initial'}</span>
                                            </div>
                                            <div onClick={() => setSelectedSignatureFont('fancySignature')} className={`${selectedSignatureFont === 'fancySignature' && 'border-blue-900'} cursor-pointer col-span-12 text-3xl border-2 border-dashed flex font-fancySignature justify-between rounded-lg py-4 mt-3 px-4`}>
                                                <span>{signatureData.signature || 'Your Signature'}</span>
                                                <span>{signatureData.initial || 'Your Initial'}</span>
                                            </div>
                                        </div>
                                    </div>}

                                    {/* Draw signature manually */}
                                    {tabMenu === 'draw' && <div>
                                        <div className='flex space-x-5'>
                                            <DrawCanvas ref={canvasRef} height={200} width={450} textColor={selectedPencilColor} title="Signature" />
                                            <DrawCanvas height={200} width={250} textColor={selectedPencilColor} title="Initial" />
                                        </div>
                                        <div className='flex space-x-3 mt-5'>
                                            <div className='h-12 w-12 cursor-pointer flex justify-center items-center bg-[#353535] rounded-t-md' onClick={() => setSelectedPencilColor('black')}>
                                                <PiPencilSimpleLineDuotone className='h-7 w-7 text-white' />
                                            </div>
                                            <div className='h-12 w-12 cursor-pointer flex justify-center items-center bg-blue-600 rounded-t-md' onClick={() => setSelectedPencilColor('blue')}>
                                                <PiPencilSimpleLineDuotone className='h-7 w-7 text-white' />
                                            </div>
                                            <div className='h-12 w-12 cursor-pointer flex justify-center items-center bg-red-500 rounded-t-md' onClick={() => setSelectedPencilColor('red')}>
                                                <PiPencilSimpleLineDuotone className='h-7 w-7 text-white' />
                                            </div>
                                        </div>
                                    </div>}

                                    {/* Upload Signature */}
                                    {tabMenu === 'upload' && <div>
                                        <div className='flex space-x-5'>
                                            <UploadArea title="Signature" height={200} width={450} uploadedFile={(file) => { }} />
                                            <UploadArea title="Initial" height={200} width={250} />
                                        </div>
                                    </div>}

                                </div>
                                <div className="bg-gray-50 px-4 py-3 flex flex-row-reverse sm:px-6">
                                    <button
                                        type="button"
                                        className="inline-flex justify-center items-center rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 ml-3 w-auto"
                                        onClick={() => { handleUpload() }}
                                    >
                                        Upload
                                    </button>
                                    <button
                                        type="button"
                                        className="inline-flex justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 mt-0 w-auto"
                                        onClick={() => closeModal(false)}
                                        ref={cancelButtonRef}
                                    >
                                        Cancel
                                    </button>
                                </div>
                            </DialogPanel>
                        </TransitionChild>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    )
}
