import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';

export const UploadArea = ({ title, height, width, uploadedFile }) => {

  const [image, setImage] = useState(null);
  const dispatch = useDispatch()

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleButtonClick = () => {
    document.getElementById("fileInput").click();
  };

  useEffect(() => {if (image) uploadedFile({type: 'upload', img: image})}, [image])

  return (
    <div className='mt-6'>
        <div className='flex justify-between items-end my-3 px-2'>
            <h4 className='text-lg'>{title}</h4>
            <h5 className='cursor-pointer text-sm text-blue-900'>Clear</h5>
        </div>
        <input
              type="file"
              id="fileInput"
              accept="image/*"
              onChange={handleImageChange}
              style={{ display: "none" }}
            />
            {image ? 
              <div onClick={handleButtonClick} className='rounded-lg cursor-pointer'>
                <img src={image} className='rounded-lg' alt="Uploaded" style={{ height: `${height}px`, width: `${width}px`, border: "1px solid #ccc" }} />
              </div>
            : <div style={{height: `${height}px`, width: `${width}px`}} className='flex flex-col space-y-3 bg-gray-200 rounded-lg items-center justify-center'>
            <span className='text-2xl font-medium text-gray-400'>Drag and Drop</span>
            <span className='text-lg text-gray-400'>OR</span>
            <button className='bg-gray-500/80 text-white rounded-lg py-1.5 font-medium px-4' onClick={handleButtonClick}>Choose Image</button>
        </div>
            }
    </div>
  )
}
