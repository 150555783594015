import { ArrowPathIcon, ChevronDoubleLeftIcon, ChevronDoubleRightIcon, ChevronLeftIcon, ChevronRightIcon, EllipsisHorizontalIcon, FunnelIcon, MoonIcon, TrashIcon } from '@heroicons/react/24/outline'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setCurrentDocument } from '../stores/DocumentSlice'
import { showMessage } from '../utils/helper'
import SelectBox from '../components/generic/SelectBox'
import { useNavigate } from 'react-router-dom'
import { LuLoader2 } from 'react-icons/lu'
import DateModal from '../components/generic/DateModal'
import { postAPIRequest } from '../utils/fetchAPIs'
import { esignAPI } from '../APIRoutes'

export const Documents = () => {
    const userAuthToken = useSelector(state => state.user.userToken)
    const dispatch = useDispatch()
    const [allDocuments, setAllDocuments] = useState([])
    const [pageLoad, setPageLoad] = useState('10')
    const [pageNo, setPageNo] = useState(1)
    const [totalPages, setTotalPages] = useState(0)
    const [totalDocuments, setTotalDocuments] = useState(0)
    const [loadingDocuments, setLoadingDocuments] = useState(false)
    const [filterOpen, setFilterOpen] = useState(false)
    const [showDateModal, setShowDateModal] = useState(false)
    const [reset, setReset] = useState(false)
    const [filterList, setFilterList] = useState({
        documentName: '',
        documentId: '',
        recipientEmailId: '',
        status: '',
        dateFrom: '',
        dateTo: ''
    })
    const navigate = useNavigate()

    function handlePageLoad(data) {
        setPageLoad(data.name)
    }

    function resetFilter() {
        setReset(true)
        setFilterList({
            documentName: '',
            documentId: '',
            recipientEmailId: '',
            status: '',
            dateFrom: '',
            dateTo: ''
        });
        getDocuments({
            documentName: '',
            documentId: '',
            recipientEmailId: '',
            status: '',
            dateFrom: '',
            dateTo: '',
            pageData: pageLoad,
            pageNo: 0
        }); 
        setPageNo(1)
    }

    function handleChangePageNo(e) {
        if (e.target.value > totalPages) return setPageNo(totalPages);
        setPageNo(e.target.value)
    }

    function handleSpecificDocument(selectedDocument) {
        dispatch(setCurrentDocument(selectedDocument))
        if (selectedDocument.status === 'DRAFT') {
            navigate(`/uploadDocument/${selectedDocument.documentId}?type=document`)
        } else {
            navigate(`/documents/${selectedDocument.documentId}`)
        }
    }

    function changePageNo(type) {
        if (type === 'prev') {
            if (pageNo > 1) {
                setPageNo(pageNo - 1)
            }
        }
        if (type === 'next') {
            if (pageNo < totalPages) {
                setPageNo(pageNo + 1)
            }
        }
    }

    function convertDateIntoStandardFormat(date) {
        const currentDate = new Date(date)
        return `${String(currentDate.getDate()).padStart(2, '0')}-${String(currentDate.getMonth()).padStart(2, '0')}-${currentDate.getFullYear()}`;
    }

    function getDateDaysBefore(days) {
        // Create a new Date object for the current date
        const currentDate = new Date();
    
        // Subtract the specified number of days from the current date
        currentDate.setDate(currentDate.getDate() - days);
    
        return currentDate;
    }

    function updateDateFilter(value) {
        if (value.name === 'Custom') {
            setShowDateModal(true)
        }
        else {
            let dateFrom = ''
            if (value.id == 1) {
                dateFrom = getDateDaysBefore(30)
            } else {
                dateFrom = getDateDaysBefore(7)
            }
            const currentDate = getDateDaysBefore(0)
            setFilterList({
                ...filterList,
                dateFrom: convertDateIntoStandardFormat(dateFrom),
                dateTo: convertDateIntoStandardFormat(currentDate)
            })
        } 
    }
    
    function updateDateFilterFromModal(dates) {
        setFilterList({
            ...filterList,
            dateFrom: convertDateIntoStandardFormat(dates.startdate),
            dateTo: convertDateIntoStandardFormat(dates.enddate)
        })
    }

    /**
     * Function to get documents list from server
     */
    async function getDocuments(bodyData='', defaultPageNo=false) {
        try {        
            if (pageNo > 0) {
                setLoadingDocuments(true)
                let body = {
                    "dateFrom": filterList.dateFrom,
                    "dateTo": filterList.dateTo,
                    "docId": filterList.documentId,
                    "documentName": filterList.documentName,
                    "recipientEmailId": filterList.recipientEmailId,
                    "status": filterList.status,
                    "pageNo": defaultPageNo ? '1' : pageNo - 1,
                    "pageData": pageLoad
                }
                if (bodyData) {
                    body = bodyData 
                }
                const headers = {
                    AuthToken: userAuthToken,
                    'Content-Type': 'application/json'
                }

                const getAllDocumentResponse = await postAPIRequest(`${esignAPI}/getDocuments`, body, headers)
                if (getAllDocumentResponse) {
                    setAllDocuments(getAllDocumentResponse.response.data)
                    setTotalDocuments(getAllDocumentResponse.response.totalData)
                    setTotalPages(getAllDocumentResponse.response.totalPages)
                }
            }
        } catch (error) {
            showMessage(error.response.data.Response.response, 'error')
        } finally{
            setLoadingDocuments(false)
        }
    }

    useEffect(() => {
        if (userAuthToken) getDocuments()
    }, [userAuthToken, pageNo])

    useEffect(() => {
        setPageNo(1)
        if (userAuthToken) getDocuments()
    }, [pageLoad, userAuthToken])

  return (
    <div className='flex flex-col justify-end items-end w-full my-24'>
        <div className='w-[79%]'>
            {showDateModal && <DateModal selectedDate={updateDateFilterFromModal} closeModal={() => setShowDateModal(false)} />}
            <div className='flex px-16 justify-between items-center'>
                <div className='flex space-x-5 justify-center items-center'>
                    <div>
                        {`View ${allDocuments.length ? ((pageNo - 1) * pageLoad) + 1 : 0} - ${pageNo * pageLoad > totalDocuments ? totalDocuments : pageNo * pageLoad} of ${totalDocuments}`}
                    </div>
                    <div className='flex justify-center items-center space-x-5'>
                        <span>Show</span>
                        <SelectBox list={[{id: 0, name: '10'}, {id: 1, name: '20'}, {id: 2, name: '30'}]} updateSelectedValue={handlePageLoad} />
                    </div>
                    <TrashIcon className='w-5 h-5' />
                    <ArrowPathIcon className={`${loadingDocuments && 'animate-spin'} w-5 h-5 cursor-pointer hover:scale-110 hover:ease-in-out`} onClick={() => {!loadingDocuments && getDocuments(); setPageNo(1)}} />
                </div>
                <div className='flex space-x-5 items-center'>
                    <ChevronDoubleLeftIcon className={`${pageNo === 1 && '!cursor-not-allowed opacity-50'} w-5 h-5 cursor-pointer`} onClick={() => setPageNo(1)} />
                    <ChevronLeftIcon className={`${pageNo === 1 && '!cursor-not-allowed opacity-50'} w-5 h-5 cursor-pointer`} onClick={() => changePageNo('prev')} />
                    <input type="number" name="pageNo" value={pageNo} onChange={(e) => handleChangePageNo(e)} className='w-12 h-8 flex text-center border rounded-md focus:outline-none' />
                    <ChevronRightIcon className={`${pageNo === totalPages && '!cursor-not-allowed opacity-50'} w-5 h-5 cursor-pointer`} onClick={() => changePageNo('next')} />
                    <ChevronDoubleRightIcon className={`${pageNo === totalPages && '!cursor-not-allowed opacity-50'} w-5 h-5 cursor-pointer`} onClick={() => setPageNo(totalPages)} />
                    <span>|</span>
                    <FunnelIcon className='w-5 h-5 cursor-pointer' onClick={() => {setFilterOpen(!filterOpen);}} />
                </div>
            </div>

            <div className='mx-16 mt-5 border rounded-lg'>
                <div className='border-b bg-gray-200'>
                    <div className='flex w-[100%] px-4 py-3 gap-x-4 font-semibold text-gray-800 text-base'>
                        <span className='w-[5%] flex items-center'>S.No.</span>
                        <span className='w-[20%] flex items-center'>Document Name</span>
                        <span className='w-[15%] flex items-center'>Document Id</span>
                        <span className='w-[20%] flex items-center'>Recipients</span>
                        <span className='w-[8%] flex items-center'>Esign Expiry</span>
                        <span className='w-[10%] flex'>Created On</span>
                        <span className='w-[12%] flex items-center justify-center'>Status</span>
                        <span className='w-[10%] flex items-center justify-center'>Action</span>
                    </div>
                    {filterOpen && <div className='flex w-[100%] relative px-4 py-3 gap-x-4 font-semibold text-gray-800 text-base'>
                        <span className='w-[5%] flex' />
                        <span className='w-[20%] flex'>
                            <input type="text" name="documentName" onChange={(e) => {setFilterList({ ...filterList, [e.target.name]: e.target.value })}} value={filterList.documentName} className='w-56 rounded-lg h-[38px] focus:outline-none px-1.5 font-normal border border-gray-300' />
                        </span>
                        <span className='w-[15%] flex'>
                            <input type="text" name="documentId" onChange={(e) => {setFilterList({ ...filterList, [e.target.name]: e.target.value })}} value={filterList.documentId} className='w-40 rounded-lg h-[38px] focus:outline-none px-1.5 font-normal border border-gray-300' />
                        </span>
                        <span className='w-[20%] flex'>
                            <input type="text" name="recipientEmailId" onChange={(e) => {setFilterList({ ...filterList, [e.target.name]: e.target.value })}} value={filterList.recipientEmailId} className='w-56 rounded-lg h-[38px] focus:outline-none px-1.5 font-normal border border-gray-300' />
                        </span>
                        <span className='w-[8%] flex' />
                        <span className='w-[10%] flex'>
                            <SelectBox resetValue={reset} list={['--select--', '7 days', '30 days', 'Custom'].map((status, index) => ({id: index, name: status}))} updateSelectedValue={(value) => {value.id === 0 ? setReset(false) : updateDateFilter(value)}} valueResetted={() => setReset(false)} />
                        </span>
                        <span className='w-[12%] flex justify-center'>

                            <SelectBox resetValue={reset} list={['--select--', 'In Progress', 'Expired', 'Completed', 'Draft'].map((status, index) => ({id: index, name: status}))} updateSelectedValue={(value) => {value.id === 0 ? setReset(false) : setFilterList({...filterList, status: value.name.toLocaleUpperCase()})}} valueResetted={() => setReset(false)} />
                        </span>
                        <button className='w-[10%] flex border-2 bg-green-600 rounded-lg hover:bg-green-700/80 justify-center items-center text-white ' onClick={() => getDocuments()}>Filter</button>
                        <span className='absolute text-indigo-500 cursor-pointer right-7 underline hover:text-indigo-700 text-sm -top-2' onClick={() => {resetFilter();}}>Clear Filter</span>
                    </div>}
                </div>
                <div className='divide-y divide-gray-300'>
                    {
                        loadingDocuments ? <div className='flex justify-center space-x-3 items-center text-3xl font-semibold text-gray-400 h-96'>
                            <LuLoader2 className='w-8 h-8 animate-spin' />
                            <span>
                                Fetching Documents
                            </span>
                        </div> :
                        allDocuments.length ? allDocuments.map((document, index) => {
                            return (
                                <div key={document.documentId} className='flex w-[100%] text-gray-800 font-medium px-4 gap-x-4 text-sm min-h-12 py-3.5 hover:bg-gray-100'>
                                    <span className='w-[5%] flex'>{((pageNo - 1) * pageLoad) + index + 1}</span>
                                    <span className='w-[20%] flex hover:underline cursor-pointer truncate' onClick={() => handleSpecificDocument(document)}>{document.documentName}</span>
                                    <span className='w-[15%] flex'>{document.documentId}</span>
                                    <div className='w-[20%] flex flex-col justify-start items-start'>{
                                        document.esignSettingsEntity?.esignReciptants.map((recipient) => {
                                            return (
                                                <div key={recipient.recipientId}>{recipient.email}</div>
                                            )
                                        })
                                    }</div>
                                    <span className='w-[8%] flex'>{document.esignSettingsEntity?.esignExpiry}</span>
                                    <span className='w-[10%] pl-5 flex'>-</span>
                                    <span className={`w-[12%] px-4`}><span className={`${document.status === 'DRAFT' ? 'bg-blue-500' : document.status === 'IN PROGRESS' ? 'bg-yellow-500' : document.status === 'EXPIRED' ? 'bg-red-500' : 'bg-green-500'} text-white flex border h-fit justify-center items-center py-1`}>{document.status}</span></span>
                                    <span className='w-[10%] flex justify-center'><EllipsisHorizontalIcon className='w-5 h-5' /></span>
                                </div>
                            )
                        }) : <div className='flex justify-center items-center text-3xl font-semibold text-gray-400 h-96'>No documents found</div>
                    }
                </div>
            </div>
        </div>
    </div>
  )
}
