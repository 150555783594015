import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { verifyEsignDocument, verifyEsignDocumentOtp } from '../stores/DocumentSlice'
import { setToken } from '../stores/UserStore'
import { showMessage } from '../utils/helper'
import { useLocation, useNavigate } from 'react-router-dom'
import Cookies from "js-cookie"
import { getAPIRequest, postAPIRequest } from '../utils/fetchAPIs'
import { thirdView } from '../APIRoutes'

export function ValidateEsign() {
    const [isEsignScheduled, setIsEsignScheduled] = useState(false)
    const [scheduledDate, setScheduledDate] = useState('')
    const [authType, setAuthType] = useState('')
    const [authId, setAuthId] = useState('')
    const [documentAuthType, setDocumentAuthType] = useState('')
    const [recipientId, setRecipientId] = useState('')
    const [documentId, setDocumentId] = useState('')
    const [authTypeData, setAuthTypeData] = useState({
        email: '',
        mobile: '',
        aadhar: ''
    })
    const [showOtpField, setShowOtpField] = useState(true)
    const [maskedInfo, setMaskedInfo] = useState('')
    const dispatch = useDispatch()
    const location = useLocation()
    const [otp, setOtp] = useState('')
    const navigate = useNavigate()

    async function verifyEsignOtp() {
        try {            
            const postData = {
                "medium" : authType.toLowerCase(),    
                "otp": parseInt(otp),
                "authId": authId,
                "recipientId": recipientId,
                "docId": documentId
            }
    
            const verifyOTPResponse = await postAPIRequest(`${thirdView}/verifyEsignOtp`, postData, {}, false)
            if (verifyOTPResponse.message === 'SUCCESS') {
                const token =  verifyOTPResponse.response.token
                dispatch(setToken(token))
                Cookies.set('AuthToken', token, { expires: 7 });
                navigate(`/documentViewer?documentId=${documentId}&viewType=esignView`)
            }
        } catch (error) {
            showMessage(error.response.data.Response.response, 'error')
        }
    }

    const onChangeData = (e) => {
        setAuthTypeData({ ...authTypeData, [e.target.name]: e.target.value })
    }

    async function sendDocumentOtp(data) {
        try {            
            const documentOTPResponse = await postAPIRequest(`${thirdView}/verifyEsign`, data, {}, false)
            const authType = documentOTPResponse.response.authType
            setAuthId(documentOTPResponse.response.authId)
            setDocumentAuthType(authType.toLowerCase())
            setAuthType(authType)
            if (authType.toLocaleLowerCase() === 'email') {
                setMaskedInfo(documentOTPResponse.response.recipientEmailIdMasked)
            }
        } catch (error) {
            showMessage(error.response.data.Response.response, 'error')
        }
    }

    useEffect(() => {
        const queryParameters = location.search.split('&')
        const data = {
            docId: queryParameters[0].split('?documentId=')[1],
            recipientId: queryParameters[1].split('recipientId=')[1]
        }
        setRecipientId(data.recipientId)
        setDocumentId(data.docId)
        sendDocumentOtp(data)
    }, [])

    return (
        <div className="relative z-10 focus:outline-none">
            <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                <div className="flex min-h-full items-center justify-center p-4">
                    <div
                        className="w-full max-w-lg px-10 rounded-xl border-2 bg-white p-6 backdrop-blur-2xl duration-300 ease-out data-[closed]:transform-[scale(95%)] data-[closed]:opacity-0"
                    >
                        <div className='flex flex-col items-start mb-10'>
                            <div as="h3" className="text-xl mt-5 font-medium">
                                Validate Esign
                            </div>
                        </div>

                        {!showOtpField && <div>
                            {documentAuthType === 'email' && <div>
                                <label htmlFor="email" className='flex ml-2 text-lg'>Email</label>
                                <input type="email" name="email" id="email" onChange={(e) => onChangeData(e)} value={authTypeData.email} className='w-full border border-gray-300 py-2 px-3 rounded-lg' />
                            </div>}
                            {documentAuthType === 'mobile' && <div>
                                <label htmlFor="mobile" className='flex ml-2 text-lg'>Mobile</label>
                                <input type="number" name="mobile" id="mobile" onChange={(e) => onChangeData(e)} value={authTypeData.mobile} className='w-full border border-gray-300 py-2 px-3 rounded-lg' />
                            </div>}
                            {documentAuthType === 'email_mobile' && <div>
                                <label htmlFor="email" className='flex ml-2 text-lg'>Email</label>
                                <input type="email" name="email" id="email" onChange={(e) => onChangeData(e)} value={authTypeData.email} className='w-full border border-gray-300 py-2 px-3 rounded-lg' />
                                <label htmlFor="mobile" className='flex ml-2 text-lg'>Mobile</label>
                                <input type="number" name="mobile" id="mobile" onChange={(e) => onChangeData(e)} value={authTypeData.mobile} className='w-full border border-gray-300 py-2 px-3 rounded-lg' />
                            </div>
                            }
                            {documentAuthType === 'aadhar' && <div>
                                <label htmlFor="aadhar" className='flex ml-2 text-lg'>Aadhar</label>
                                <input type="number" name="aadar" id="aadhar" onChange={(e) => onChangeData(e)} value={authTypeData.aadhar} className='w-full border border-gray-300 py-2 px-3 rounded-lg' />
                            </div>}
                        </div>}

                            <div className="flex mb-3">
                                Otp has been sent to your {maskedInfo} 
                            </div>
                        {showOtpField && <div className='flex w-full  items-center space-x-5'>
                            <span className='w-full flex'>Enter Otp</span>
                            <input type="number" name="otp" value={otp} onChange={(e) => setOtp(e.target.value)} className='w-96 border border-gray-300 py-2 px-3 rounded-lg' />
                        </div>}

                        <div className="mt-8 mb-5">
                            <button
                                className="inline-flex items-center gap-2 rounded-md bg-gray-700 py-1.5 px-3 text-sm/6 font-semibold text-white shadow-inner shadow-white/10 focus:outline-none data-[hover]:bg-gray-600 data-[focus]:outline-1 data-[focus]:outline-white data-[open]:bg-gray-700"
                                onClick={() => verifyEsignOtp()}
                            >
                                Verify Otp
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
