import axios from "axios"
import { useEffect, useState } from "react"
import { authAPI } from "../APIRoutes"
import { useDispatch, useSelector } from "react-redux"
import { setPermissions, setToken, setUserRole } from "../stores/UserStore"
import { useNavigate } from "react-router-dom"
import Cookies from "js-cookie"
import { decodeJWT, showMessage } from "../utils/helper"
import Logo from "../assets/images/logo.png"
import ForgotPasswordModal from "../components/ForgotPasswordModal"
import { postAPIRequest } from "../utils/fetchAPIs"

export default function Login() {

    const [credentials, setCredentials] = useState({ email: '', password: '' })
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const role = useSelector(state => state.user.userRoles)
    const [showForgotPasswordModal, setShowForgotPasswordModal] = useState(false)

    const userAuthToken = useSelector(state => state.user.userToken)

    const handleChange = (e) => {
        setCredentials({ ...credentials, [e.target.name]: e.target.value })
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        try {
          
          const postData = { 
            Email: credentials.email,
            Password: credentials.password 
          }
          const loginResponse = await postAPIRequest(`${authAPI}/login`, postData, {}, false)
          if (loginResponse.message === 'SUCCESS') {
            const token = loginResponse.response.token
            const permissions = loginResponse.response.webPagePermission.permissionList
            const decodedJWT = decodeJWT(token)
            dispatch(setToken(token))
            dispatch(setUserRole(decodedJWT.roles))
            dispatch(setPermissions(permissions))
            Cookies.set('AuthToken', token, { expires: 7 }); // Set a cookie with a 7-day expiration
            Cookies.set('Permissions', JSON.stringify(permissions), { expires: 7 }); // Set a cookie with a 7-day expiration
            if (decodedJWT.roles === 'ADMIN') {
              navigate('/manageUser')
            } else if (decodedJWT.roles === 'USER') {
              navigate('/')
            } else if (decodedJWT.roles === 'SUPERADMIN') {
              navigate('/manageAdmin')
            }
          } else {
            showMessage(loginResponse.Response.response, 'error')
          }
        } catch (error) {
          showMessage(error.response.data.Response.response, 'error')
        }
    }

    const handleForgotPassword = () => {
      setShowForgotPasswordModal(true)
    }

    useEffect(() => {
        if (userAuthToken) {
          if (role === 'ADMIN') {
            navigate('/manageUser')
          } else if (role === 'USER') {
            navigate('/')
          }
        }
    }, [userAuthToken, role])

    return (
      <>
        {showForgotPasswordModal && <ForgotPasswordModal closeModal={() => setShowForgotPasswordModal(false)} />}
        <div className="flex items-center h-screen flex-1 flex-col justify-center px-6 py-12 lg:px-8">
          <div className="w-full border-2 py-20 rounded-xl shadow-xl px-16 max-w-lg mx-auto items-center">
            <div className="w-full">
              <img
                alt="Your Company"
                src={Logo}
                className="mx-auto h-24 w-auto"
              />
              <h2 className="mt-6 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
                Sign in to your account
              </h2>
            </div>
    
            <div className="mt-10 w-full">
              <div className="">
                <div>
                  <label htmlFor="email" className="block ml-2 text-start text-lg font-medium leading-6 text-gray-900">
                    Email address
                  </label>
                  <div className="mt-2">
                    <input
                      id="email"
                      name="email"
                      type="email"
                      required
                      autoComplete="email"
                      value={credentials.email}
                      onChange={handleChange}
                      className="block w-full rounded-lg border-0 py-3 px-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:leading-6"
                    />
                  </div>
                </div>
    
                <div className="mt-6">
                  <div className="flex items-center justify-between">
                    <label htmlFor="password" className="block ml-2 text-lg font-medium leading-6 text-gray-900">
                      Password
                    </label>
                    <div>
                      <p onClick={() => handleForgotPassword()} className="font-semibold cursor-pointer text-indigo-600 hover:text-indigo-500">
                        Forgot password?
                      </p>
                    </div>
                  </div>
                  <div className="mt-2">
                    <input
                      id="password"
                      name="password"
                      type="password"
                      required
                      value={credentials.password}
                      onChange={handleChange}
                      autoComplete="current-password"
                      className="block w-full px-3 rounded-lg border-0 py-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:leading-6"
                    />
                  </div>
                </div>
    
                <div>
                  <button
                    className="flex mt-10 w-full justify-center rounded-lg bg-blue-800 px-3 py-3 text-lg font-semibold leading-6 text-white shadow-sm hover:bg-blue-900 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    onClick={(e) => handleSubmit(e)}
                  >
                    Sign in
                  </button>
                </div>
              </div>
    
            </div>
          </div>
        </div>
      </>
    )
  }
  