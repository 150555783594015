import React, { useEffect, useState } from 'react'
import { getAPIRequest } from '../utils/fetchAPIs'
import { thirdView } from '../APIRoutes'
import { useSelector } from 'react-redux'
import { showMessage } from '../utils/helper'
import { ArrowDownTrayIcon, EnvelopeIcon, PrinterIcon } from '@heroicons/react/24/outline'

export const EsignSuccessfull = () => {

    const userAuthToken = useSelector(state => state.user.userToken)
    const [documentDownloadable, setDocumentDownloadable] = useState(false)
    const [downloadLink, setDownloadLink] = useState('')

    async function getDownloadLink() {
        const headers = {
            'AuthToken': userAuthToken
        };
    
        try {
            // Fetch the PDF response
            const response = await fetch(`${thirdView}/downloadSignedDocument`, {
                method: 'GET',
                headers: headers
            });
    
            if (!response.ok) {
                throw new Error(`Failed to fetch document: ${response.statusText}`);
            }
    
            // Convert response to Blob
            const pdfBlob = await response.blob();
    
            // Check Blob type and size for validation
    
            // Create a URL for the PDF blob
            const blobUrl = URL.createObjectURL(pdfBlob);
    
            // Update state
            setDocumentDownloadable(true);
            setDownloadLink(blobUrl);
        } catch (error) {
            showMessage(error.response.data.Response.response, 'error')
        }
    }
    

    const handleDownloadClick = async () => {
        const url = downloadLink;
        if (url) {
          const anchor = document.createElement("a");
          anchor.href = url;
          anchor.download = "document.pdf"; // Set default file name
          anchor.click();
    
          // Optionally revoke the object URL to free up memory
          setTimeout(() => URL.revokeObjectURL(url), 1000);
        }
    };

    useEffect(() => {
        if (userAuthToken) {
            getDownloadLink()
        }
    }, [userAuthToken])
    return (
        <div className='my-24 w-full h-full'>
            <div className='flex flex-col justify-center items-center w-full h-full'>
                {
                    documentDownloadable ? <div>
                        <span>You have successfully signed this document.</span>
                        <div className='flex space-x-5 mt-5'>
                            <button onClick={handleDownloadClick} className='flex space-x-2 justify-center items-center border py-2 px-4 rounded-lg'>
                                <span>Download</span>
                                <ArrowDownTrayIcon className='w-5 h-5' />
                            </button>
                            <button className='flex space-x-2 justify-center items-center border py-2 px-4 rounded-lg'>
                                <span>Print</span>
                                <PrinterIcon className='w-5 h-5' />
                            </button>
                            <button className='flex space-x-2 justify-center items-center border py-2 px-4 rounded-lg'>
                                <span>Email to me</span>
                                <EnvelopeIcon className='w-5 h-5' />
                            </button>
                        </div>
                    </div> :
                        <div>
                            Document will be available once everybody has signed.
                        </div>
                }
            </div>
        </div>
    )
}
