let navigateFunction = null;

export const setNavigate = (navigate) => {
    navigateFunction = navigate;
};

export const navigateToPage = (path) => {
    if (navigateFunction) {
        navigateFunction(path);
    } else {
        console.error('Navigate function is not set');
    }
};